import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from '../../api/axios-config'
import toast from 'react-hot-toast'

interface IBargainAd {
  specAdminId: number
  bankruptcyId: number
  date: string
  active: boolean
  shortInformation: string
  fileId: number | null
  address: {
    leafId: number
    streetName: string
    code: string | null
    house: string | null
    number: string | null
    index: string | null
  }
}

export const GetBargainAd = createAsyncThunk(
  'get/bargainad',
  async (
    {
      page,
      pageSize,
      bankruptcyId,
      specAdminId,
      dateStart,
      dateEnd,
      active,
    }: {
      page: number
      pageSize: number
      bankruptcyId?: number | undefined
      specAdminId?: number | undefined
      dateStart?: string | undefined
      dateEnd?: string | undefined
      active?: boolean | undefined
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(
        `bargain-ad/${page}/${pageSize}`,
        {
          bankruptcyId,
          specAdminId,
          dateStart,
          dateEnd,
          active,
        }
      )
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const GetBargainAdById = createAsyncThunk(
  'get/bargainad/byId',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`bargain-ad/${id}`)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const PostBargainAd = createAsyncThunk(
  'post/bargainad',
  async (data: IBargainAd, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`bargain-ad`, data)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

export const PutBargainAd = createAsyncThunk(
  'put/bargainad',
  async (
    { id, data }: { id: number; data: IBargainAd },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.put(`bargain-ad/${id}`, data)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

export const DeleteBargainAd = createAsyncThunk(
  'delete/bargainad',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`bargain-ad/${id}`)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

export const GetBargainAdPublic = createAsyncThunk(
  'get/bargainad/public',
  async (
    { page, pageSize }: { page: number; pageSize: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.get(
        `bargain-ad/public/${page}/${pageSize}`
      )
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

interface IInitialState {
  MainBargainAd: null | any
  singleData: any
  loadingBargainAd: boolean
  fetchCheck: boolean
  publicData: any
}

const initialState: IInitialState = {
  MainBargainAd: null,
  singleData: undefined,
  loadingBargainAd: false,
  fetchCheck: false,
  publicData: undefined,
}

export const BargainAdSlice = createSlice({
  name: 'bargainAd',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(GetBargainAdPublic.pending, (state) => {
      state.loadingBargainAd = true
    })
    builder.addCase(GetBargainAdPublic.fulfilled, (state, { payload }) => {
      state.loadingBargainAd = false
      state.publicData = payload
    })
    builder.addCase(GetBargainAdPublic.rejected, (state) => {
      state.loadingBargainAd = false
    })
    //-----------------------------------------------------
    builder.addCase(GetBargainAd.pending, (state) => {
      state.loadingBargainAd = true
    })
    builder.addCase(GetBargainAd.fulfilled, (state, { payload }) => {
      state.loadingBargainAd = false
      state.MainBargainAd = payload
    })
    builder.addCase(GetBargainAd.rejected, (state) => {
      state.loadingBargainAd = false
    })
    //-----------------------------------------------------
    builder.addCase(GetBargainAdById.pending, (state) => {
      state.loadingBargainAd = true
    })
    builder.addCase(GetBargainAdById.fulfilled, (state, { payload }) => {
      state.loadingBargainAd = false
      state.singleData = payload
    })
    builder.addCase(GetBargainAdById.rejected, (state) => {
      state.loadingBargainAd = false
    })
    //-----------------------------------------------------
    builder.addCase(PostBargainAd.pending, (state) => {
      state.loadingBargainAd = true
    })
    builder.addCase(PostBargainAd.fulfilled, (state) => {
      state.loadingBargainAd = false
      state.fetchCheck = !state.fetchCheck
    })
    builder.addCase(PostBargainAd.rejected, (state) => {
      state.loadingBargainAd = false
    })
    //-----------------------------------------------------
    builder.addCase(PutBargainAd.pending, (state) => {
      state.loadingBargainAd = true
    })
    builder.addCase(PutBargainAd.fulfilled, (state) => {
      state.loadingBargainAd = false
      state.fetchCheck = !state.fetchCheck
    })
    builder.addCase(PutBargainAd.rejected, (state) => {
      state.loadingBargainAd = false
    })
    //-----------------------------------------------------
    builder.addCase(DeleteBargainAd.pending, (state) => {
      state.loadingBargainAd = true
    })
    builder.addCase(DeleteBargainAd.fulfilled, (state) => {
      state.loadingBargainAd = false
      state.fetchCheck = !state.fetchCheck
    })
    builder.addCase(DeleteBargainAd.rejected, (state) => {
      state.loadingBargainAd = false
    })
    //-----------------------------------------------------
  },
})
