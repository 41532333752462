import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from '../../api/axios-config'
import toast from 'react-hot-toast'

export const getLegalForm = createAsyncThunk(
  'get/LegalForm',
  async (
    { page, pageSize }: { page: number; pageSize: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.get(`legal-form/${page}/${pageSize}`)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const getLegalFormSimple = createAsyncThunk(
  'get/LegalForm/simple',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`legal-form/simple`)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const postLegalForm = createAsyncThunk(
  'post/LegalForm',
  async (body: postData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`legal-form`, body)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

export const putLegalForm = createAsyncThunk(
  'put/LegalForm',
  async ({ id, body }: { id: number; body: postData }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`legal-form/${id}`, body)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

export const removeLegalForm = createAsyncThunk(
  'remove/LegalForm',
  async ({ id }: { id: any }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`legal-form/${id}`)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

interface postData {
  ruName: string | null
  kgName: string | null
  active: boolean | null
  ruShortName: string | null
  kgShortName: string | null
}
interface data {
  id: number
  ruName: string | null
  kgName: string | null
  active: boolean | null
  ruShortName: string | null
  kgShortName: string | null
}

interface IInitialState {
  loadingLegalForm: boolean
  LegalFormData: {
    page: data[]
    totalCount: number
  } | null
  fetchCheck: any
  LegalFormDataSimple: any
}

const initialState: IInitialState = {
  loadingLegalForm: false,
  LegalFormData: null,
  LegalFormDataSimple: null,
  fetchCheck: null,
}

export const LegalFormSlice = createSlice({
  name: 'LegalForm',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getLegalFormSimple.pending, (state) => {
      state.loadingLegalForm = true
    })
    builder.addCase(getLegalFormSimple.fulfilled, (state, { payload }) => {
      state.loadingLegalForm = false
      state.LegalFormDataSimple = payload
    })
    builder.addCase(getLegalFormSimple.rejected, (state) => {
      state.loadingLegalForm = false
    })
    //--------------------
    builder.addCase(getLegalForm.pending, (state) => {
      state.loadingLegalForm = true
    })
    builder.addCase(getLegalForm.fulfilled, (state, { payload }) => {
      state.loadingLegalForm = false
      state.LegalFormData = payload
    })
    builder.addCase(getLegalForm.rejected, (state) => {
      state.loadingLegalForm = false
    })
    //--------------------
    builder.addCase(postLegalForm.pending, (state) => {
      state.loadingLegalForm = true
    })
    builder.addCase(postLegalForm.fulfilled, (state, { payload }) => {
      state.loadingLegalForm = false
      state.fetchCheck = payload
    })
    builder.addCase(postLegalForm.rejected, (state) => {
      state.loadingLegalForm = false
    })
    //--------------------
    builder.addCase(putLegalForm.pending, (state) => {
      state.loadingLegalForm = true
    })
    builder.addCase(putLegalForm.fulfilled, (state, { payload }) => {
      state.loadingLegalForm = false
      state.fetchCheck = payload
    })
    builder.addCase(putLegalForm.rejected, (state) => {
      state.loadingLegalForm = false
    })
    //--------------------
    builder.addCase(removeLegalForm.pending, (state) => {
      state.loadingLegalForm = true
    })
    builder.addCase(removeLegalForm.fulfilled, (state, { payload }) => {
      state.loadingLegalForm = false
      state.fetchCheck = payload
    })
    builder.addCase(removeLegalForm.rejected, (state) => {
      state.loadingLegalForm = false
    })
  },
})
