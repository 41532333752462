import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from '../../api/axios-config'
import toast from 'react-hot-toast'

interface IForm1 {
  bankruptcyId: number | null
  quarter: number | null
  year: number | null
  //not
  accountDebtCostDate: number | null
  accountDebtCostStart: number | null
  buildingCostDate: number | null
  buildingCostStart: number | null
  cashCostDate: number | null
  cashCostStart: number | null
  equipmentCostDate: number | null
  equipmentCostStart: number | null
  ibeCostDate: number | null
  ibeCostStart: number | null
  intangibleAssetsCostDate: number | null
  intangibleAssetsCostStart: number | null
  landCostDate: number | null
  landCostStart: number | null
  materialsCostDate: number | null
  materialsCostStart: number | null
  otherCostDate: number | null
  otherCostStart: number | null
  liquidationTotalCostDate: number | null
  liquidationTotalCostStart: number | null
  transportCostDate: number | null
  transportCostStart: number | null
  unfinishedBuildingCostDate: number | null
  unfinishedBuildingCostStart: number | null
  objectsCostDate: number | null
  objectsCostStart: number | null
  pledgedPropertiesTotalDate: number | null
  pledgedPropertiesTotalStart: number | null
  residentialBuildingsTotalCostDate: number | null
  residentialBuildingsTotalCostStart: number | null
  totalCostDate: number | null
  totalCostStart: number | null
  sectionId: number | null
  fileId: number | null
  pledgedProperties: Form1PledgedProperties[] | null
  objects: Form1Objects[] | null
  residentialBuildings: Form1ResidentialBuildings[] | null
}

interface Form1Objects {
  forma1Id: number
  costDate: number | null
  costStart: number | null
  name: string | null
}

interface Form1PledgedProperties {
  forma1Id: number
  costDate: number | null
  costStart: number | null
  name: string | null
}

interface Form1ResidentialBuildings {
  forma1Id: number
  costDate: number | null
  costStart: number | null
  name: string | null
}

export const getForm1 = createAsyncThunk(
  'get/Form1',
  async (
    {
      page,
      pageSize,
      years,
      bankruptcyIds,
      statusIds,
      quarter,
      localityIds,
    }: {
      page: number
      pageSize: number
      bankruptcyIds: number[] | undefined
      years: number[] | undefined
      statusIds: number[] | undefined
      quarter: number[] | undefined
      localityIds: number[] | undefined
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(
        `form-1/get/${page}/${pageSize}`,
        {
          years,
          bankruptcyIds,
          statusIds,
          quarter,
          localityIds,
        }
      )
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const getForm1ById = createAsyncThunk(
  'get/Form1/byId',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`form-1/${id}`)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const postForm1 = createAsyncThunk(
  'post/Form1',
  async (data: IForm1, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`form-1`, data)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const putForm1 = createAsyncThunk(
  'put/Form1',
  async ({ data, id }: { data: IForm1; id: number }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`form-1/${id}`, data)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

export const deleteForm1 = createAsyncThunk(
  'delete/Form1',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`form-1/${id}`)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

interface IInitialState {
  loadingForm1: boolean
  fetchCheck: any
  Form1DataTax: any
  Form1Data: any
  singleData: any
}

const initialState: IInitialState = {
  Form1DataTax: null,
  loadingForm1: false,
  fetchCheck: null,
  Form1Data: null,
  singleData: undefined,
}

export const Form1Slice = createSlice({
  name: 'Form1',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getForm1ById.pending, (state) => {
      state.loadingForm1 = true
    })
    builder.addCase(getForm1ById.fulfilled, (state, { payload }) => {
      state.loadingForm1 = false
      state.singleData = payload
    })
    builder.addCase(getForm1ById.rejected, (state) => {
      state.loadingForm1 = false
    })
    //--------------------
    builder.addCase(getForm1.pending, (state) => {
      state.loadingForm1 = true
    })
    builder.addCase(getForm1.fulfilled, (state, { payload }) => {
      state.loadingForm1 = false
      state.Form1Data = payload
    })
    builder.addCase(getForm1.rejected, (state) => {
      state.loadingForm1 = false
    })
    //--------------------
    builder.addCase(postForm1.pending, (state) => {
      state.loadingForm1 = true
    })
    builder.addCase(postForm1.fulfilled, (state) => {
      state.loadingForm1 = false
      state.fetchCheck = !state.fetchCheck
    })
    builder.addCase(postForm1.rejected, (state) => {
      state.loadingForm1 = false
      state.fetchCheck = !state.fetchCheck
    })
    //--------------------
    builder.addCase(putForm1.pending, (state) => {
      state.loadingForm1 = true
    })
    builder.addCase(putForm1.fulfilled, (state) => {
      state.loadingForm1 = false
      state.fetchCheck = !state.fetchCheck
    })
    builder.addCase(putForm1.rejected, (state) => {
      state.loadingForm1 = false
      state.fetchCheck = !state.fetchCheck
    })
    //--------------------
    builder.addCase(deleteForm1.pending, (state) => {
      state.loadingForm1 = true
    })
    builder.addCase(deleteForm1.fulfilled, (state) => {
      state.loadingForm1 = false
      state.fetchCheck = !state.fetchCheck
    })
    builder.addCase(deleteForm1.rejected, (state) => {
      state.loadingForm1 = false
      state.fetchCheck = !state.fetchCheck
    })
    //--------------------
  },
})
