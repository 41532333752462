import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from '../../api/axios-config'
import toast from 'react-hot-toast'

export const getInitiationStatus = createAsyncThunk(
  'get/InitiationStatus',
  async (
    { page, pageSize }: { page: number; pageSize: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.get(
        `initiation-status/${page}/${pageSize}`
      )
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)
export const getInitiationStatusSimple = createAsyncThunk(
  'get/InitiationStatus/simple',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`initiation-status/simple`)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const postInitiationStatus = createAsyncThunk(
  'post/InitiationStatus',
  async (body: postData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`initiation-status`, body)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

export const putInitiationStatus = createAsyncThunk(
  'put/InitiationStatus',
  async ({ id, body }: { id: number; body: postData }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`initiation-status/${id}`, body)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

export const removeInitiationStatus = createAsyncThunk(
  'remove/InitiationStatus',
  async ({ id }: { id: any }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`initiation-status/${id}`)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error('Возможно этот элемент используется')
      rejectWithValue((error as Error).message)
    }
  }
)

interface postData {
  ruName: string
  kgName: string
  active: boolean | null
}
interface data {
  id: number
  ruName: string
  kgName: string
  active: boolean | null
}

interface IInitialState {
  loadingInitiationStatus: boolean
  InitiationStatusData: {
    page: data[]
    totalCount: number
  } | null
  fetchCheck: any
  simpleData: any
}

const initialState: IInitialState = {
  loadingInitiationStatus: false,
  InitiationStatusData: null,
  fetchCheck: null,
  simpleData: null,
}

export const InitiationStatusSlice = createSlice({
  name: 'InitiationStatus',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getInitiationStatusSimple.pending, (state) => {
      state.loadingInitiationStatus = true
    })
    builder.addCase(
      getInitiationStatusSimple.fulfilled,
      (state, { payload }) => {
        state.loadingInitiationStatus = false
        state.simpleData = payload
      }
    )
    builder.addCase(getInitiationStatusSimple.rejected, (state) => {
      state.loadingInitiationStatus = false
    })
    //--------------------
    builder.addCase(getInitiationStatus.pending, (state) => {
      state.loadingInitiationStatus = true
    })
    builder.addCase(getInitiationStatus.fulfilled, (state, { payload }) => {
      state.loadingInitiationStatus = false
      state.InitiationStatusData = payload
    })
    builder.addCase(getInitiationStatus.rejected, (state) => {
      state.loadingInitiationStatus = false
    })
    //--------------------
    builder.addCase(postInitiationStatus.pending, (state) => {
      state.loadingInitiationStatus = true
    })
    builder.addCase(postInitiationStatus.fulfilled, (state, { payload }) => {
      state.loadingInitiationStatus = false
      state.fetchCheck = payload
    })
    builder.addCase(postInitiationStatus.rejected, (state) => {
      state.loadingInitiationStatus = false
    })
    //--------------------
    builder.addCase(putInitiationStatus.pending, (state) => {
      state.loadingInitiationStatus = true
    })
    builder.addCase(putInitiationStatus.fulfilled, (state, { payload }) => {
      state.loadingInitiationStatus = false
      state.fetchCheck = payload
    })
    builder.addCase(putInitiationStatus.rejected, (state) => {
      state.loadingInitiationStatus = false
    })
    //--------------------
    builder.addCase(removeInitiationStatus.pending, (state) => {
      state.loadingInitiationStatus = true
    })
    builder.addCase(removeInitiationStatus.fulfilled, (state, { payload }) => {
      state.loadingInitiationStatus = false
      state.fetchCheck = payload
    })
    builder.addCase(removeInitiationStatus.rejected, (state) => {
      state.loadingInitiationStatus = false
    })
  },
})
