import { Suspense, lazy } from 'react'
import { motion } from 'framer-motion'
import { useAppSelector } from '../app/hooks'
import { GerbLoader } from '../components/module/loaders/gerb-loader'
import { Route, Routes } from 'react-router-dom'

const ModeratorRoutes = lazy(() => import('./ModeratorRoutes'))
const JuristRoutes = lazy(() => import('./JuristRoutes'))
const AdminRoutes = lazy(() => import('./AdminRoutes'))
const ManagerRoutes = lazy(() => import('./ManagerRoutes'))
const UserRoutes = lazy(() => import('./UserRoutes'))

const AppRoutes = () => {
  const { role } = useAppSelector((state) => state.auth)
  let routes

  switch (role) {
    case 'ROLE_MODERATOR':
      routes = (
        <>
          <Route path='/moderator/*' element={<ModeratorRoutes />} />
        </>
      )
      break
    case 'ROLE_LENSER':
      routes = (
        <>
          <Route path='/lenser/*' element={<JuristRoutes />} />
        </>
      )
      break
    case 'ROLE_ADMIN':
      routes = (
        <>
          <Route path='/admin/*' element={<AdminRoutes />} />
        </>
      )
      break
    case 'ROLE_MANAGER':
      routes = (
        <>
          <Route path='/manager/*' element={<ManagerRoutes />} />
        </>
      )
      break
    default:
      routes = undefined
  }

  return (
    <Suspense fallback={<GerbLoader />}>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className='page'
      >
        <Routes>
          {routes}
          <Route path='/*' element={<UserRoutes />} />
        </Routes>
      </motion.div>
    </Suspense>
  )
}

export default AppRoutes
