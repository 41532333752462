import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from '../../api/axios-config'
import toast from 'react-hot-toast'

export const getOwn = createAsyncThunk(
  'get/Own',
  async (
    { page, pageSize }: { page: number; pageSize: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.get(`own/${page}/${pageSize}`)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const getOwnSimple = createAsyncThunk(
  'get/Own/simple',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`own/simple`)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const postOwn = createAsyncThunk(
  'post/Own',
  async (body: postData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`own`, body)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

export const putOwn = createAsyncThunk(
  'put/Own',
  async ({ id, body }: { id: number; body: postData }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`own/${id}`, body)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

export const removeOwn = createAsyncThunk(
  'remove/Own',
  async ({ id }: { id: any }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`own/${id}`)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error('Возможно этот элемент используется')
      rejectWithValue((error as Error).message)
    }
  }
)

interface postData {
  ruName: string | null
  kgName: string | null
  active: boolean | null
}
interface data {
  id: number
  ruName: string | null
  kgName: string | null
  active: boolean | null
}

interface IInitialState {
  loadingOwn: boolean
  OwnData: {
    page: data[]
    totalCount: number
  } | null
  fetchCheck: any
  simpleData: any
}

const initialState: IInitialState = {
  loadingOwn: false,
  OwnData: null,
  fetchCheck: null,
  simpleData: null,
}

export const OwnSlice = createSlice({
  name: 'Own',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getOwnSimple.pending, (state) => {
      state.loadingOwn = true
    })
    builder.addCase(getOwnSimple.fulfilled, (state, { payload }) => {
      state.loadingOwn = false
      state.simpleData = payload
    })
    builder.addCase(getOwnSimple.rejected, (state) => {
      state.loadingOwn = false
    })
    //--------------------
    builder.addCase(getOwn.pending, (state) => {
      state.loadingOwn = true
    })
    builder.addCase(getOwn.fulfilled, (state, { payload }) => {
      state.loadingOwn = false
      state.OwnData = payload
    })
    builder.addCase(getOwn.rejected, (state) => {
      state.loadingOwn = false
    })
    //--------------------
    builder.addCase(postOwn.pending, (state) => {
      state.loadingOwn = true
    })
    builder.addCase(postOwn.fulfilled, (state, { payload }) => {
      state.loadingOwn = false
      state.fetchCheck = payload
    })
    builder.addCase(postOwn.rejected, (state) => {
      state.loadingOwn = false
    })
    //--------------------
    builder.addCase(putOwn.pending, (state) => {
      state.loadingOwn = true
    })
    builder.addCase(putOwn.fulfilled, (state, { payload }) => {
      state.loadingOwn = false
      state.fetchCheck = payload
    })
    builder.addCase(putOwn.rejected, (state) => {
      state.loadingOwn = false
    })
    //--------------------
    builder.addCase(removeOwn.pending, (state) => {
      state.loadingOwn = true
    })
    builder.addCase(removeOwn.fulfilled, (state, { payload }) => {
      state.loadingOwn = false
      state.fetchCheck = payload
    })
    builder.addCase(removeOwn.rejected, (state) => {
      state.loadingOwn = false
    })
  },
})
