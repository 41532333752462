import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { CiImageOff, CiImageOn } from 'react-icons/ci'
import { AiOutlineClose } from 'react-icons/ai'
import { setPanelModal } from '../../../app/slice/localState.slice'
import { useLocation } from 'react-router-dom'
import { memo, useEffect } from 'react'
import { MdImage } from 'react-icons/md'
import { BiReset } from 'react-icons/bi'
import styles from './style.module.scss'

export const BviCustom = memo(
  ({
    settings,
    setSettings,
  }: {
    settings: {
      theme:
        | null
        | 'whiteblack'
        | 'blackwhite'
        | 'blueblack'
        | 'yellowblack'
        | 'browngreen'
      fontSize: null | 'f14' | 'f16' | 'f17' | 'f18' | 'f19'
      image: null | 'grayscale' | 'off'
      spacing: null | 'middle' | 'big'
      lineHeight: null | 'lh40' | 'lh30'
      fontFamily: null | 'with'
    }
    setSettings: any
  }) => {
    const dispatch = useAppDispatch()
    const { panelModal } = useAppSelector((state) => state.localState)
    const { pathname } = useLocation()
    const role = pathname.split('/')[1]

    function ResetSettings() {
      setSettings({
        theme: null,
        fontSize: null,
        image: null,
        spacing: null,
        lineHeight: null,
        fontFamily: null,
      })
    }
    function handleClose() {
      dispatch(setPanelModal(false))
    }

    useEffect(() => {
      if (
        role === 'moderator' ||
        role === 'lenser' ||
        role === 'manager' ||
        role === 'admin'
      ) {
        ResetSettings()
        handleClose()
      }
    }, [pathname])

    return (
      <div
        className={styles.menu_container}
        style={{
          height: panelModal ? 'auto' : '0px',
          padding: panelModal ? '20px' : '0px',
          opacity: panelModal ? 1 : 0,
        }}
      >
        <div className={styles.item_container}>
          <h4>Размер шрифта</h4>
          <ul className={styles.list}>
            <li
              className={
                settings.fontSize !== 'f14' ? styles.f14 : styles.f14_active
              }
              onClick={() => setSettings({ ...settings, fontSize: 'f14' })}
            >
              A
            </li>
            <li
              className={
                settings.fontSize !== 'f16' ? styles.f16 : styles.f16_active
              }
              onClick={() => setSettings({ ...settings, fontSize: 'f16' })}
            >
              A
            </li>
            <li
              className={
                settings.fontSize !== 'f17' ? styles.f17 : styles.f17_active
              }
              onClick={() => setSettings({ ...settings, fontSize: 'f17' })}
            >
              A
            </li>
            <li
              className={
                settings.fontSize !== 'f18' ? styles.f18 : styles.f18_active
              }
              onClick={() => setSettings({ ...settings, fontSize: 'f18' })}
            >
              A
            </li>
            <li
              className={
                settings.fontSize !== 'f19' ? styles.f19 : styles.f19_active
              }
              onClick={() => setSettings({ ...settings, fontSize: 'f19' })}
            >
              A
            </li>
          </ul>
        </div>
        <div className={styles.item_container}>
          <h4>Цвет сайта</h4>
          <ul className={styles.list}>
            <li
              className={styles.whiteblack}
              onClick={() => setSettings({ ...settings, theme: 'whiteblack' })}
            >
              {settings.theme === 'whiteblack' ? '✓' : 'ц'}
            </li>
            <li
              className={styles.blackwhite}
              onClick={() => setSettings({ ...settings, theme: 'blackwhite' })}
            >
              {settings.theme === 'blackwhite' ? '✓' : 'ц'}
            </li>
            <li
              className={styles.blueblack}
              onClick={() => setSettings({ ...settings, theme: 'blueblack' })}
            >
              {settings.theme === 'blueblack' ? '✓' : 'ц'}
            </li>
            <li
              className={styles.yellowblack}
              onClick={() => setSettings({ ...settings, theme: 'yellowblack' })}
            >
              {settings.theme === 'yellowblack' ? '✓' : 'ц'}
            </li>
            <li
              className={styles.browngreen}
              onClick={() => setSettings({ ...settings, theme: 'browngreen' })}
            >
              {settings.theme === 'browngreen' ? '✓' : 'ц'}
            </li>
          </ul>
        </div>
        <div className={styles.item_container}>
          <h4>Изображение</h4>
          <ul className={styles.list}>
            <li
              className={
                settings.image === 'grayscale'
                  ? styles.image_active
                  : styles.image
              }
              onClick={() => setSettings({ ...settings, image: 'grayscale' })}
            >
              <MdImage /> Ч/Б
            </li>
            <li
              className={
                settings.image === null ? styles.image_active : styles.image
              }
              onClick={() => setSettings({ ...settings, image: null })}
            >
              <CiImageOn /> Вкл
            </li>
            <li
              className={
                settings.image === 'off' ? styles.image_active : styles.image
              }
              onClick={() => setSettings({ ...settings, image: 'off' })}
            >
              <CiImageOff /> Выкл
            </li>
          </ul>
        </div>
        <div className={styles.item_container}>
          <h4>Кернинг</h4>
          <ul className={styles.list}>
            <li
              className={
                settings.spacing === null
                  ? styles.additional_active
                  : styles.additional
              }
              onClick={() => setSettings({ ...settings, spacing: null })}
            >
              Стандартный
            </li>
            <li
              className={
                settings.spacing === 'middle'
                  ? styles.additional_active
                  : styles.additional
              }
              onClick={() => setSettings({ ...settings, spacing: 'middle' })}
            >
              Средний
            </li>
            <li
              className={
                settings.spacing === 'big'
                  ? styles.additional_active
                  : styles.additional
              }
              onClick={() => setSettings({ ...settings, spacing: 'big' })}
            >
              Большой
            </li>
          </ul>
        </div>
        <div className={styles.item_container}>
          <h4>Интервал</h4>
          <ul className={styles.list}>
            <li
              className={
                settings.lineHeight === null
                  ? styles.additional_active
                  : styles.additional
              }
              onClick={() => setSettings({ ...settings, lineHeight: null })}
            >
              Стандартный
            </li>
            <li
              className={
                settings.lineHeight === 'lh30'
                  ? styles.additional_active
                  : styles.additional
              }
              onClick={() => setSettings({ ...settings, lineHeight: 'lh30' })}
            >
              Средний
            </li>
            <li
              className={
                settings.lineHeight === 'lh40'
                  ? styles.additional_active
                  : styles.additional
              }
              onClick={() => setSettings({ ...settings, lineHeight: 'lh40' })}
            >
              Большой
            </li>
          </ul>
        </div>
        <div className={styles.item_container}>
          <h4>Гарнитура</h4>
          <ul className={styles.list}>
            <li
              className={
                settings.fontFamily === null
                  ? styles.additional_active
                  : styles.additional
              }
              onClick={() => setSettings({ ...settings, fontFamily: null })}
            >
              Без засечек
            </li>
            <li
              className={
                settings.fontFamily === 'with'
                  ? styles.additional_active
                  : styles.additional
              }
              onClick={() => setSettings({ ...settings, fontFamily: 'with' })}
            >
              С засечками
            </li>
          </ul>
        </div>
        <div className={styles.item_container}>
          <h4>Действия</h4>
          <ul className={styles.list}>
            <li className={styles.additional} onClick={ResetSettings}>
              <BiReset /> Сбросить
            </li>
            <li className={styles.additional} onClick={handleClose}>
              <AiOutlineClose /> Закрыть
            </li>
          </ul>
        </div>
      </div>
    )
  }
)
