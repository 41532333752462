import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from '../../api/axios-config'
import toast from 'react-hot-toast'

export const NewsGetAll = createAsyncThunk(
  'get/news/all',
  async (
    {
      page,
      size,
      lang,
      desc,
      active,
    }: {
      page: number
      size: number
      lang: number | null
      desc: string
      active: boolean | null
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.get(
        `news/full?page=${page}&pageSize=${size}` +
          `${lang !== null && lang !== undefined ? `&lang=${lang}` : ''}` +
          `${
            active !== undefined && active !== null ? `&active=${active}` : ''
          }` +
          `${desc !== '' && desc !== undefined ? `&desc=${desc}` : ''}`
      )
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const NewsGetSimplfield = createAsyncThunk(
  'get/news/simplefield',
  async (
    { page, size, lang }: { page: number; size: number; lang: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.get(
        `news/simplefield/${page}/${size}/${lang}`
      )
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const NewsGetItem = createAsyncThunk(
  'get/newsinner',
  async ({ id }: { id: number }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`news/only/${id}`)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const PostNews = createAsyncThunk(
  'post/news',
  async (body: IPostData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('news', body)
      response && toast.success('Успешно добавлено !')
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

export const UpdateNews = createAsyncThunk(
  'put/news',
  async (
    { body, id }: { body: IPostData; id: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.put(`news/${id}`, body)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

export const DeleteNews = createAsyncThunk(
  'put/delete',
  async ({ id }: { id: number }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`news/${id}`)
      console.log(response)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

interface IPostData {
  title: string
  desc: string
  active: boolean
  lang: number | null
  date: string
  content: string | null
}

interface IInitialState {
  loadingNews: boolean

  MainNewsData: null | {
    totalCount: number
    page: {
      active: boolean
      date: string
      desc: string
      id: number
      lang: string
      list: any
      title: string
      content: string
    }[]
  }
  InnerNewsData: {
    id: number
    title: string
    desc: string
    active: boolean
    date: null
    lang: number
    list: []
    content: string
  } | null
  SimpleNewsData: {
    page: {
      id: number
      title: string
      desc: string
      active: boolean
      date: null
      lang: string
      content: string
    }[]
    totalCount: number
  } | null
  fetchCheck: boolean
}

const initialState: IInitialState = {
  SimpleNewsData: null,
  MainNewsData: null,
  InnerNewsData: null,
  loadingNews: false,
  fetchCheck: false,
}

export const NewsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(NewsGetAll.pending, (state) => {
      state.loadingNews = true
    })
    builder.addCase(NewsGetAll.fulfilled, (state, { payload }) => {
      state.loadingNews = false
      state.MainNewsData = payload
    })
    builder.addCase(NewsGetAll.rejected, (state) => {
      state.loadingNews = false
    })
    ////////////////////////////////////
    builder.addCase(NewsGetSimplfield.pending, (state) => {
      state.loadingNews = true
    })
    builder.addCase(NewsGetSimplfield.fulfilled, (state, { payload }) => {
      state.loadingNews = false
      state.SimpleNewsData = payload
    })
    builder.addCase(NewsGetSimplfield.rejected, (state) => {
      state.loadingNews = false
    })
    //--------------------
    builder.addCase(NewsGetItem.pending, (state) => {
      state.loadingNews = true
    })
    builder.addCase(NewsGetItem.fulfilled, (state, { payload }) => {
      state.loadingNews = false
      state.InnerNewsData = payload
    })
    builder.addCase(NewsGetItem.rejected, (state) => {
      state.loadingNews = false
    })
    //--------------------
    builder.addCase(PostNews.pending, (state) => {
      state.loadingNews = true
    })
    builder.addCase(PostNews.fulfilled, (state) => {
      state.loadingNews = false
      state.fetchCheck = !state.fetchCheck
    })
    builder.addCase(PostNews.rejected, (state) => {
      state.loadingNews = false
    })
    //--------------------
    builder.addCase(UpdateNews.pending, (state) => {
      state.loadingNews = true
    })
    builder.addCase(UpdateNews.fulfilled, (state) => {
      state.loadingNews = false
      state.fetchCheck = !state.fetchCheck
    })
    builder.addCase(UpdateNews.rejected, (state) => {
      state.loadingNews = false
    })
    //--------------------
    builder.addCase(DeleteNews.pending, (state) => {
      state.loadingNews = true
    })
    builder.addCase(DeleteNews.fulfilled, (state) => {
      state.loadingNews = false
      state.fetchCheck = !state.fetchCheck
    })
    builder.addCase(DeleteNews.rejected, (state) => {
      state.loadingNews = false
    })
  },
})
