import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from '../../api/axios-config'
import toast from 'react-hot-toast'

interface IForm2 {
  bankruptcyId: number | null
  quarter: number | null
  year: number | null
  sectionId: number | null
  bargains: IForm2Bargains[] | null
}

interface IForm2Bargains {
  alienationMethodId: number | null
  date: string | null
  name: string | null
  noteId: number | null
  propertyNumber: string | null
  cost: number | null
  evalutionCost: number | null
  realizedTo: string | null
}

export const getForm2 = createAsyncThunk(
  'get/Form2',
  async (
    {
      page,
      pageSize,
      years,
      bankruptcyIds,
      statusIds,
      quarter,
      localityIds,
    }: {
      page: number
      pageSize: number
      bankruptcyIds: number[] | undefined
      years: number[] | undefined
      statusIds: number[] | undefined
      quarter: number[] | undefined
      localityIds: number[] | undefined
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(
        `form-2/get/${page}/${pageSize}`,
        {
          years,
          bankruptcyIds,
          statusIds,
          quarter,
          localityIds,
        }
      )
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const getForm2ById = createAsyncThunk(
  'get/Form2/byid',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`form-2/${id}`)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const postForm2 = createAsyncThunk(
  'post/Form2',
  async (data: IForm2, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`form-2`, data)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

export const putForm2 = createAsyncThunk(
  'put/Form2',
  async ({ data, id }: { data: IForm2; id: number }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`form-2/${id}`, data)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

export const deleteForm2 = createAsyncThunk(
  'delete/Form2',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`form-2/${id}`)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

interface IInitialState {
  loadingForm2: boolean
  fetchCheck: any
  Form2Data: any
  singleData: any
}

const initialState: IInitialState = {
  loadingForm2: false,
  fetchCheck: null,
  Form2Data: null,
  singleData: undefined,
}

export const Form2Slice = createSlice({
  name: 'Form2',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getForm2ById.pending, (state) => {
      state.loadingForm2 = true
    })
    builder.addCase(getForm2ById.fulfilled, (state, { payload }) => {
      state.loadingForm2 = false
      state.singleData = payload
    })
    builder.addCase(getForm2ById.rejected, (state) => {
      state.loadingForm2 = false
    })
    //--------------------
    builder.addCase(getForm2.pending, (state) => {
      state.loadingForm2 = true
    })
    builder.addCase(getForm2.fulfilled, (state, { payload }) => {
      state.loadingForm2 = false
      state.Form2Data = payload
    })
    builder.addCase(getForm2.rejected, (state) => {
      state.loadingForm2 = false
    })
    //--------------------
    builder.addCase(postForm2.pending, (state) => {
      state.loadingForm2 = true
    })
    builder.addCase(postForm2.fulfilled, (state) => {
      state.loadingForm2 = false
      state.fetchCheck = !state.fetchCheck
    })
    builder.addCase(postForm2.rejected, (state) => {
      state.loadingForm2 = false
      state.fetchCheck = !state.fetchCheck
    })
    //--------------------
    builder.addCase(putForm2.pending, (state) => {
      state.loadingForm2 = true
    })
    builder.addCase(putForm2.fulfilled, (state) => {
      state.loadingForm2 = false
      state.fetchCheck = !state.fetchCheck
    })
    builder.addCase(putForm2.rejected, (state) => {
      state.loadingForm2 = false
      state.fetchCheck = !state.fetchCheck
    })
    //--------------------
    builder.addCase(deleteForm2.pending, (state) => {
      state.loadingForm2 = true
    })
    builder.addCase(deleteForm2.fulfilled, (state) => {
      state.loadingForm2 = false
      state.fetchCheck = !state.fetchCheck
    })
    builder.addCase(deleteForm2.rejected, (state) => {
      state.loadingForm2 = false
      state.fetchCheck = !state.fetchCheck
    })
    //--------------------
  },
})
