import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from '../../api/axios-config'
import toast from 'react-hot-toast'

export const getContact = createAsyncThunk(
  'get/contact',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/contact`)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const putContact = createAsyncThunk(
  'put/contact',
  async (
    { id, body }: { id: number; body: IpostData },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.put(`/contact/${id}`, body)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

interface IpostData {
  email: string
  phoneNumber: string
  address: string
  fax: string
}

interface Idata {
  id: number
  email: string
  phoneNumber: string
  address: string
  fax: string
}

interface IInitialState {
  contactData: Idata | null
  loadingContact: boolean
  fetchCheck: any
}

const initialState: IInitialState = {
  contactData: null,
  loadingContact: false,
  fetchCheck: null,
}

export const ContactSlice = createSlice({
  name: 'contacs',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getContact.pending, (state) => {
      state.loadingContact = true
    })
    builder.addCase(getContact.fulfilled, (state, { payload }) => {
      state.loadingContact = false
      state.contactData = payload
    })
    builder.addCase(getContact.rejected, (state) => {
      state.loadingContact = false
    })
    //////////////////////////////////////////////////////////
    builder.addCase(putContact.pending, (state) => {
      state.loadingContact = true
    })
    builder.addCase(putContact.fulfilled, (state, { payload }) => {
      state.loadingContact = false
      state.fetchCheck = payload
    })
    builder.addCase(putContact.rejected, (state) => {
      state.loadingContact = false
    })
  },
})
