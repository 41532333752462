import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from '../../api/axios-config'

export const getSpecAdminType = createAsyncThunk(
  'get/specAdminType',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`spec-admin/types`)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

interface IInitialState {
  loadingSpecStatus: boolean
  specTypeData: any
}

const initialState: IInitialState = {
  loadingSpecStatus: false,
  specTypeData: null,
}

export const SpecAdminTypeSlice = createSlice({
  name: 'specAdminType',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getSpecAdminType.pending, (state) => {
      state.loadingSpecStatus = true
    })
    builder.addCase(getSpecAdminType.fulfilled, (state, { payload }) => {
      state.loadingSpecStatus = false
      state.specTypeData = payload
    })
    builder.addCase(getSpecAdminType.rejected, (state) => {
      state.loadingSpecStatus = false
    })
    //--------------------
  },
})
