import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from '../../api/axios-config'
import toast from 'react-hot-toast'

interface IForm3 {
  bankruptcyId: number
  quarter: number
  year: number
  balance: number | null
  cashInflowBalance: number | null
  fromCurrent: number | null
  fromExacted: number | null
  fromRealize: number | null
  cashInflowOther: number | null
  cashInflowTotal: number | null
  costs: number | null
  useOfCashOther: number | null
  repayment: number | null
  spending: number | null
  useOfCashTotal: number | null
  sectionId: number | null
}

export const getForm3 = createAsyncThunk(
  'get/Form3',
  async (
    {
      page,
      pageSize,
      years,
      bankruptcyIds,
      statusIds,
      quarter,
      localityIds,
    }: {
      page: number
      pageSize: number
      bankruptcyIds: number[] | undefined
      years: number[] | undefined
      statusIds: number[] | undefined
      quarter: number[] | undefined
      localityIds: number[] | undefined
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(
        `form-3/get/${page}/${pageSize}`,
        {
          years,
          bankruptcyIds,
          statusIds,
          quarter,
          localityIds,
        }
      )
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const getForm3ById = createAsyncThunk(
  'get/Form3/byid',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`form-3/${id}`)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const postForm3 = createAsyncThunk(
  'post/Form3',
  async (data: IForm3, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`form-3`, data)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

export const putForm3 = createAsyncThunk(
  'put/Form3',
  async ({ data, id }: { data: IForm3; id: number }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`form-3/${id}`, data)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

export const deleteForm3 = createAsyncThunk(
  'delete/Form3',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`form-3/${id}`)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

interface IInitialState {
  loadingForm3: boolean
  fetchCheck: any
  Form3Data: any
  singleData: any
}

const initialState: IInitialState = {
  loadingForm3: false,
  fetchCheck: null,
  Form3Data: null,
  singleData: undefined,
}

export const Form3Slice = createSlice({
  name: 'Form3',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getForm3ById.pending, (state) => {
      state.loadingForm3 = true
    })
    builder.addCase(getForm3ById.fulfilled, (state, { payload }) => {
      state.loadingForm3 = false
      state.singleData = payload
    })
    builder.addCase(getForm3ById.rejected, (state) => {
      state.loadingForm3 = false
    })
    //--------------------
    builder.addCase(getForm3.pending, (state) => {
      state.loadingForm3 = true
    })
    builder.addCase(getForm3.fulfilled, (state, { payload }) => {
      state.loadingForm3 = false
      state.Form3Data = payload
    })
    builder.addCase(getForm3.rejected, (state) => {
      state.loadingForm3 = false
    })
    //--------------------
    builder.addCase(postForm3.pending, (state) => {
      state.loadingForm3 = true
    })
    builder.addCase(postForm3.fulfilled, (state) => {
      state.loadingForm3 = false
      state.fetchCheck = !state.fetchCheck
    })
    builder.addCase(postForm3.rejected, (state) => {
      state.loadingForm3 = false
      state.fetchCheck = !state.fetchCheck
    })
    //--------------------
    builder.addCase(putForm3.pending, (state) => {
      state.loadingForm3 = true
    })
    builder.addCase(putForm3.fulfilled, (state) => {
      state.loadingForm3 = false
      state.fetchCheck = !state.fetchCheck
    })
    builder.addCase(putForm3.rejected, (state) => {
      state.loadingForm3 = false
      state.fetchCheck = !state.fetchCheck
    })
    //--------------------
    builder.addCase(deleteForm3.pending, (state) => {
      state.loadingForm3 = true
    })
    builder.addCase(deleteForm3.fulfilled, (state) => {
      state.loadingForm3 = false
      state.fetchCheck = !state.fetchCheck
    })
    builder.addCase(deleteForm3.rejected, (state) => {
      state.loadingForm3 = false
      state.fetchCheck = !state.fetchCheck
    })
    //--------------------
  },
})
