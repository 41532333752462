import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from '../../api/axios-config'
import toast from 'react-hot-toast'

export const getCourtCaseAct = createAsyncThunk(
  'get/CourtCaseAct',
  async (
    {
      page,
      pageSize,
      body,
    }: {
      page: number
      pageSize: number
      body: {
        periodStart: undefined | string
        periodEnd: undefined | string
        quarter: undefined | number[]
        executor: undefined | number[]
        location: undefined | number[]
      }
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(
        `court-case-act/${page}/${pageSize}`,
        body
      )
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const getCourtCaseActReport = createAsyncThunk(
  'get/CourtCaseAct/report',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`court-case-act/report`)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const getCourtCaseActById = createAsyncThunk(
  'get/CourtCaseAct/byId',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`court-case-act/${id}`)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const postCourtCaseAct = createAsyncThunk(
  'post/CourtCaseAct',
  async (body: postData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`court-case-act`, body)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

export const putCourtCaseAct = createAsyncThunk(
  'put/CourtCaseAct',
  async ({ id, body }: { id: number; body: postData }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`court-case-act/${id}`, body)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

export const removeCourtCaseAct = createAsyncThunk(
  'remove/CourtCaseAct',
  async ({ id }: { id: any }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`court-case-act/${id}`)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error('Возможно этот элемент используется')
      rejectWithValue((error as Error).message)
    }
  }
)

interface postData {
  date: string
  quarter: number
  executorId: number
  locationId: number
  text: string | null
  view: boolean
}
interface data {
  id: number
  date: string | undefined
  quarter: undefined | number
  executorId: number | undefined
  executor: any
  locationId: number | undefined
  location: any
  text: string | null
  view: boolean
}

interface IInitialState {
  loadingCourtCaseAct: boolean
  CourtCaseActData: {
    page: data[]
    totalCount: number
  } | null
  fetchCheck: any
  singleData: any
  reportData: any
}

const initialState: IInitialState = {
  loadingCourtCaseAct: false,
  CourtCaseActData: null,
  fetchCheck: null,
  singleData: undefined,
  reportData: undefined,
}

export const CourtCaseActSlice = createSlice({
  name: 'CourtCaseAct',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getCourtCaseActReport.pending, (state) => {
      state.loadingCourtCaseAct = true
    })
    builder.addCase(getCourtCaseActReport.fulfilled, (state, { payload }) => {
      state.loadingCourtCaseAct = false
      state.reportData = payload
    })
    builder.addCase(getCourtCaseActReport.rejected, (state) => {
      state.loadingCourtCaseAct = false
    })
    //--------------------
    builder.addCase(getCourtCaseActById.pending, (state) => {
      state.loadingCourtCaseAct = true
    })
    builder.addCase(getCourtCaseActById.fulfilled, (state, { payload }) => {
      state.loadingCourtCaseAct = false
      state.singleData = payload
    })
    builder.addCase(getCourtCaseActById.rejected, (state) => {
      state.loadingCourtCaseAct = false
    })
    //--------------------
    builder.addCase(getCourtCaseAct.pending, (state) => {
      state.loadingCourtCaseAct = true
    })
    builder.addCase(getCourtCaseAct.fulfilled, (state, { payload }) => {
      state.loadingCourtCaseAct = false
      state.CourtCaseActData = payload
    })
    builder.addCase(getCourtCaseAct.rejected, (state) => {
      state.loadingCourtCaseAct = false
    })
    //--------------------
    builder.addCase(postCourtCaseAct.pending, (state) => {
      state.loadingCourtCaseAct = true
    })
    builder.addCase(postCourtCaseAct.fulfilled, (state, { payload }) => {
      state.loadingCourtCaseAct = false
      state.fetchCheck = payload
    })
    builder.addCase(postCourtCaseAct.rejected, (state) => {
      state.loadingCourtCaseAct = false
    })
    //--------------------
    builder.addCase(putCourtCaseAct.pending, (state) => {
      state.loadingCourtCaseAct = true
    })
    builder.addCase(putCourtCaseAct.fulfilled, (state, { payload }) => {
      state.loadingCourtCaseAct = false
      state.fetchCheck = payload
    })
    builder.addCase(putCourtCaseAct.rejected, (state) => {
      state.loadingCourtCaseAct = false
    })
    //--------------------
    builder.addCase(removeCourtCaseAct.pending, (state) => {
      state.loadingCourtCaseAct = true
    })
    builder.addCase(removeCourtCaseAct.fulfilled, (state, { payload }) => {
      state.loadingCourtCaseAct = false
      state.fetchCheck = payload
    })
    builder.addCase(removeCourtCaseAct.rejected, (state) => {
      state.loadingCourtCaseAct = false
    })
  },
})
