import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from '../../api/axios-config'
import toast from 'react-hot-toast'

export interface ILegalPerson {
  ownId: number | null
  legalFormId: number | null
  typeId: null | number
  fileId: number | null
  tin: string | null
  code: string
  stateShare: number
  ruName: string
  kgName: string
  ruShortName: string
  kgShortName: string
  foreign: boolean
  registrationNumber: string
  email1: null | string
  email2: null | string
  phoneNumber: null | string
  fullNameOfSupervisor: null | string
  tinOfSupervisor: null | string
  countPhysicalPersons: null | number
  countLegalPersons: null | number
  totalCountPersons: null | number
  registrationCertificate: registrationCertificate
  courtAuthority: boolean
  taxAuthority: boolean
  activityTypeCode: string | null
  creationAgreement: null | string
  legalAddress: address
  actualAddress: address
}

export interface registrationCertificate {
  orderDate: string | null
  primaryData: string | null
  value: string | null
}

export interface address {
  leafId: number | null
  code: string | null
  streetName: string | null
  house: string | null
  number: string | null
  index: string | null
}

export const getLegalPersonTax = createAsyncThunk(
  'get/LegalPerson/tax',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`legal-person/tax-authority`)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const getLegalPeronDirectory = createAsyncThunk(
  'get/LegalPerson/directory',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`legal-person/directory`)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const getLegalPeronCourt = createAsyncThunk(
  'get/LegalPerson/court-authority',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`legal-person/court-authority`)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const getLegalPerson = createAsyncThunk(
  'get/LegalPerson',
  async (
    {
      page,
      pageSize,
      typeIds,
      ownIds,
      ruName,
      tin,
      localityIds,
      legalFormIds,
    }: {
      page: number
      pageSize: number
      typeIds: number[] | undefined
      ownIds: number[] | undefined
      ruName: string | undefined
      tin: string | undefined
      localityIds: number[] | undefined
      legalFormIds: number[] | undefined
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(
        `legal-person/get/${page}/${pageSize}`,
        {
          typeIds,
          ownIds,
          ruName,
          tin,
          localityIds,
          legalFormIds,
        }
      )
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const getLegalPersonById = createAsyncThunk(
  'get/LegalPerson/ById',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`legal-person/${id}`)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const postLegalPerson = createAsyncThunk(
  'post/LegalPerson',
  async (data: ILegalPerson, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`legal-person`, data)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

export const putLegalPerson = createAsyncThunk(
  'put/LegalPerson',
  async (
    { data, id }: { data: ILegalPerson; id: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.put(`legal-person/${id}`, data)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

export const deleteLegalPerson = createAsyncThunk(
  'delete/LegalPerson',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`legal-person/${id}`)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

interface IInitialState {
  loadingLegalPerson: boolean
  fetchCheck: any
  legalPersonData: any
  LegalPersonDataTax: any
  LegalPersonDataDirectory: any
  LegalPersonDataCourt: any
  singleDataLegalPerson: any
}

const initialState: IInitialState = {
  LegalPersonDataCourt: null,
  LegalPersonDataTax: null,
  LegalPersonDataDirectory: null,
  loadingLegalPerson: false,
  fetchCheck: null,
  legalPersonData: null,
  singleDataLegalPerson: null,
}

export const LegalPersonSlice = createSlice({
  name: 'LegalPerson',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getLegalPersonById.pending, (state) => {
      state.loadingLegalPerson = true
    })
    builder.addCase(getLegalPersonById.fulfilled, (state, { payload }) => {
      state.loadingLegalPerson = false
      state.singleDataLegalPerson = payload
    })
    builder.addCase(getLegalPersonById.rejected, (state) => {
      state.loadingLegalPerson = false
    })
    //--------------------
    builder.addCase(getLegalPeronDirectory.pending, (state) => {
      state.loadingLegalPerson = true
    })
    builder.addCase(getLegalPeronDirectory.fulfilled, (state, { payload }) => {
      state.loadingLegalPerson = false
      state.LegalPersonDataDirectory = payload
    })
    builder.addCase(getLegalPeronDirectory.rejected, (state) => {
      state.loadingLegalPerson = false
    })
    //--------------------
    builder.addCase(getLegalPeronCourt.pending, (state) => {
      state.loadingLegalPerson = true
    })
    builder.addCase(getLegalPeronCourt.fulfilled, (state, { payload }) => {
      state.loadingLegalPerson = false
      state.LegalPersonDataCourt = payload
    })
    builder.addCase(getLegalPeronCourt.rejected, (state) => {
      state.loadingLegalPerson = false
    })
    //--------------------
    builder.addCase(getLegalPersonTax.pending, (state) => {
      state.loadingLegalPerson = true
    })
    builder.addCase(getLegalPersonTax.fulfilled, (state, { payload }) => {
      state.loadingLegalPerson = false
      state.LegalPersonDataTax = payload
    })
    builder.addCase(getLegalPersonTax.rejected, (state) => {
      state.loadingLegalPerson = false
    })
    //--------------------
    builder.addCase(getLegalPerson.pending, (state) => {
      state.loadingLegalPerson = true
    })
    builder.addCase(getLegalPerson.fulfilled, (state, { payload }) => {
      state.loadingLegalPerson = false
      state.legalPersonData = payload
    })
    builder.addCase(getLegalPerson.rejected, (state) => {
      state.loadingLegalPerson = false
    })
    //--------------------
    builder.addCase(postLegalPerson.pending, (state) => {
      state.loadingLegalPerson = true
    })
    builder.addCase(postLegalPerson.fulfilled, (state) => {
      state.loadingLegalPerson = false
      state.fetchCheck = !state.fetchCheck
    })
    builder.addCase(postLegalPerson.rejected, (state) => {
      state.loadingLegalPerson = false
      state.fetchCheck = !state.fetchCheck
    })
    //--------------------
    builder.addCase(putLegalPerson.pending, (state) => {
      state.loadingLegalPerson = true
    })
    builder.addCase(putLegalPerson.fulfilled, (state) => {
      state.loadingLegalPerson = false
      state.fetchCheck = !state.fetchCheck
    })
    builder.addCase(putLegalPerson.rejected, (state) => {
      state.loadingLegalPerson = false
      state.fetchCheck = !state.fetchCheck
    })
    //--------------------
    builder.addCase(deleteLegalPerson.pending, (state) => {
      state.loadingLegalPerson = true
    })
    builder.addCase(deleteLegalPerson.fulfilled, (state) => {
      state.loadingLegalPerson = false
      state.fetchCheck = !state.fetchCheck
    })
    builder.addCase(deleteLegalPerson.rejected, (state) => {
      state.loadingLegalPerson = false
      state.fetchCheck = !state.fetchCheck
    })
    //--------------------
  },
})
