import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from '../../api/axios-config'
import toast from 'react-hot-toast'

interface IForm5 {
  bankruptcyId: number | null
  quarter: number | null
  year: number | null
  bargainAssessmentAccrued: number | null
  bargainAssessmentPaid: number | null
  bargainAssessmentPeriodAccrued: number | null
  bargainAssessmentPeriodPaid: number | null
  communicationAccrued: number | null
  communicationPaid: number | null
  communicationPeriodAccrued: number | null
  communicationPeriodPaid: number | null
  costAssessmentAccrued: number | null
  costAssessmentPaid: number | null
  costAssessmentPeriodAccrued: number | null
  costAssessmentPeriodPaid: number | null
  documentAssessmentAccrued: number | null
  documentAssessmentPaid: number | null
  documentAssessmentPeriodAccrued: number | null
  documentAssessmentPeriodPaid: number | null
  otherAssessmentAccrued: number | null
  otherAssessmentPaid: number | null
  otherAssessmentPeriodAccrued: number | null
  otherAssessmentPeriodPaid: number | null
  remunerationValueAccrued: number | null
  remunerationValuePaid: number | null
  remunerationValuePeriodAccrued: number | null
  remunerationValuePeriodPaid: number | null
  remunerationAdditionalPayoutAccrued: number | null
  remunerationAdditionalPayoutPaid: number | null
  remunerationAdditionalPayoutPeriodAccrued: number | null
  remunerationAdditionalPayoutPeriodPaid: number | null
  remunerationCurrentPayoutAccrued: number | null
  remunerationCurrentPayoutPaid: number | null
  remunerationCurrentPayoutPeriodAccrued: number | null
  remunerationCurrentPayoutPeriodPaid: number | null
  remunerationIncomeTaxAccrued: number | null
  remunerationIncomeTaxPaid: number | null
  remunerationIncomeTaxPeriodAccrued: number | null
  remunerationIncomeTaxPeriodPaid: number | null
  remunerationInsuranceDeductionAccrued: number | null
  remunerationInsuranceDeductionPaid: number | null
  remunerationInsuranceDeductionPeriodAccrued: number | null
  remunerationInsuranceDeductionPeriodPaid: number | null
  remunerationSpecialistValueAccrued: number | null
  remunerationSpecialistValuePaid: number | null
  remunerationSpecialistValuePeriodAccrued: number | null
  remunerationSpecialistValuePeriodPaid: number | null
  remunerationSpecialistTaxAccrued: number | null
  remunerationSpecialistTaxPaid: number | null
  remunerationSpecialistTaxPeriodAccrued: number | null
  remunerationSpecialistTaxPeriodPaid: number | null
  remunerationSpecialistInsuranceAccrued: number | null
  remunerationSpecialistInsurancePaid: number | null
  remunerationSpecialistInsurancePeriodAccrued: number | null
  remunerationSpecialistInsurancePeriodPaid: number | null
  rentAssessmentAccrued: number | null
  rentAssessmentPaid: number | null
  rentAssessmentPeriodAccrued: number | null
  rentAssessmentPeriodPaid: number | null
  totalExpenseAccrued: number | null
  totalExpensePaid: number | null
  totalExpensePeriodAccrued: number | null
  totalExpensePeriodPaid: number | null
  transportAssessmentAccrued: number | null
  transportAssessmentPaid: number | null
  transportAssessmentPeriodAccrued: number | null
  transportAssessmentPeriodPaid: number | null
  electricPowerAccrued: number | null
  electricPowerPaid: number | null
  electricPowerPeriodAccrued: number | null
  electricPowerPeriodPaid: number | null
  fuelAccrued: number | null
  fuelPaid: number | null
  fuelPeriodAccrued: number | null
  fuelPeriodPaid: number | null
  materialAccrued: number | null
  materialPaid: number | null
  materialPeriodAccrued: number | null
  materialPeriodPaid: number | null
  otherAccrued: number | null
  otherPaid: number | null
  otherPeriodAccrued: number | null
  otherPeriodPaid: number | null
  salaryIncomeTaxAccrued: number | null
  salaryIncomeTaxPaid: number | null
  salaryIncomeTaxPeriodAccrued: number | null
  salaryIncomeTaxPeriodPaid: number | null
  salaryInsuranceDeductionAccrued: number | null
  salaryInsuranceDeductionPaid: number | null
  salaryInsuranceDeductionPeriodAccrued: number | null
  salaryInsuranceDeductionPeriodPaid: number | null
  salaryValueAccrued: number | null
  salaryValuePaid: number | null
  salaryValuePeriodAccrued: number | null
  salaryValuePeriodPaid: number | null
  totalProductionAssessmentAccrued: number | null
  totalProductionAssessmentPaid: number | null
  totalProductionAssessmentPeriodAccrued: number | null
  totalProductionAssessmentPeriodPaid: number | null
  totalAccrued: number | null
  totalPaid: number | null
  totalPeriodAccrued: number | null
  totalPeriodPaid: number | null
  sectionId: number | null
}

export const getForm5 = createAsyncThunk(
  'get/Form5',
  async (
    {
      page,
      pageSize,
      years,
      bankruptcyIds,
      statusIds,
      quarter,
      localityIds,
    }: {
      page: number
      pageSize: number
      bankruptcyIds: number[] | undefined
      years: number[] | undefined
      statusIds: number[] | undefined
      quarter: number[] | undefined
      localityIds: number[] | undefined
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(
        `form-5/get/${page}/${pageSize}`,
        {
          years,
          bankruptcyIds,
          statusIds,
          quarter,
          localityIds,
        }
      )
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const getForm5ById: any = createAsyncThunk(
  'get/Form5/byId',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`form-5/${id}`)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const postForm5 = createAsyncThunk(
  'post/Form5',
  async (data: IForm5, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`form-5`, data)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

export const putForm5 = createAsyncThunk(
  'put/Form5',
  async ({ data, id }: { data: IForm5; id: number }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`form-5/${id}`, data)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

export const deleteForm5 = createAsyncThunk(
  'delete/Form5',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`form-5/${id}`)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

interface IInitialState {
  loadingForm5: boolean
  fetchCheck: any
  Form5Data: any
  singleData: any
}

const initialState: IInitialState = {
  loadingForm5: false,
  fetchCheck: null,
  Form5Data: null,
  singleData: undefined,
}

export const Form5Slice = createSlice({
  name: 'Form5',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getForm5ById.pending, (state) => {
      state.loadingForm5 = true
    })
    builder.addCase(getForm5ById.fulfilled, (state, { payload }) => {
      state.loadingForm5 = false
      state.singleData = payload
    })
    builder.addCase(getForm5ById.rejected, (state) => {
      state.loadingForm5 = false
    })
    //--------------------
    builder.addCase(getForm5.pending, (state) => {
      state.loadingForm5 = true
    })
    builder.addCase(getForm5.fulfilled, (state, { payload }) => {
      state.loadingForm5 = false
      state.Form5Data = payload
    })
    builder.addCase(getForm5.rejected, (state) => {
      state.loadingForm5 = false
    })
    //--------------------
    builder.addCase(postForm5.pending, (state) => {
      state.loadingForm5 = true
    })
    builder.addCase(postForm5.fulfilled, (state) => {
      state.loadingForm5 = false
      state.fetchCheck = !state.fetchCheck
    })
    builder.addCase(postForm5.rejected, (state) => {
      state.loadingForm5 = false
      state.fetchCheck = !state.fetchCheck
    })
    //--------------------
    builder.addCase(putForm5.pending, (state) => {
      state.loadingForm5 = true
    })
    builder.addCase(putForm5.fulfilled, (state) => {
      state.loadingForm5 = false
      state.fetchCheck = !state.fetchCheck
    })
    builder.addCase(putForm5.rejected, (state) => {
      state.loadingForm5 = false
      state.fetchCheck = !state.fetchCheck
    })
    //--------------------
    builder.addCase(deleteForm5.pending, (state) => {
      state.loadingForm5 = true
    })
    builder.addCase(deleteForm5.fulfilled, (state) => {
      state.loadingForm5 = false
      state.fetchCheck = !state.fetchCheck
    })
    builder.addCase(deleteForm5.rejected, (state) => {
      state.loadingForm5 = false
      state.fetchCheck = !state.fetchCheck
    })
    //--------------------
  },
})
