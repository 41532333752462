import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from '../../api/axios-config'
import toast from 'react-hot-toast'

export const getAllHtml = createAsyncThunk(
  'get/htmlAll',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`html-page/all`)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const GetHTMLSingle = createAsyncThunk(
  'get/html/single',
  async ({ id }: { id: number }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`html-page/single/${id}`)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const GetHTMLByType = createAsyncThunk(
  'get/html/type',
  async (
    { type, lang }: { type: string; lang: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.get(
        `html-page/type-lang/${type}/${lang}`
      )
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const postHTML = createAsyncThunk(
  'post/html',
  async ({ body }: { body: postData }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`html-page`, body)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

export const putHTML = createAsyncThunk(
  'put/html',
  async ({ id, body }: { id: number; body: postData }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`html-page/${id}`, body)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

interface IlistData {
  id: number
  type: string
  lang: string
  content: string
  createdAt: any
  updatedAt: any
}

interface postData {
  type: string
  lang: string
  content: string
}

interface data {
  id: number
  type: string
  lang: string
  content: any
  createdAt: string
  updatedAt: string
}

interface IInitialState {
  HtmlData: data | null
  loadingHtml: boolean
  listData: IlistData[] | null
  putData: data | null
  postCheck: any
  putCheck: any
}

const initialState: IInitialState = {
  HtmlData: null,
  loadingHtml: false,
  listData: null,
  putData: null,
  postCheck: null,
  putCheck: null,
}

export const HTLMSlice = createSlice({
  name: 'html',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(GetHTMLSingle.pending, (state) => {
      state.loadingHtml = true
    })
    builder.addCase(GetHTMLSingle.fulfilled, (state, { payload }: any) => {
      state.loadingHtml = false
      state.HtmlData = payload
    })
    builder.addCase(GetHTMLSingle.rejected, (state) => {
      state.loadingHtml = false
    })
    //////////////////////////////////////
    builder.addCase(getAllHtml.pending, (state) => {
      state.loadingHtml = true
    })
    builder.addCase(getAllHtml.fulfilled, (state, { payload }: any) => {
      state.loadingHtml = false
      state.listData = payload
    })
    builder.addCase(getAllHtml.rejected, (state) => {
      state.loadingHtml = false
    })
    ////////////////////////////////////
    builder.addCase(GetHTMLByType.pending, (state) => {
      state.loadingHtml = true
    })
    builder.addCase(GetHTMLByType.fulfilled, (state, { payload }: any) => {
      state.loadingHtml = false
      state.HtmlData = payload
    })
    builder.addCase(GetHTMLByType.rejected, (state) => {
      state.loadingHtml = false
    })
    ////////////////////////////////////
    builder.addCase(postHTML.pending, (state) => {
      state.loadingHtml = true
    })
    builder.addCase(postHTML.fulfilled, (state, { payload }) => {
      state.loadingHtml = false
      state.postCheck = payload
    })
    builder.addCase(postHTML.rejected, (state) => {
      state.loadingHtml = false
    })
    //////////////////////////////////////
    builder.addCase(putHTML.pending, (state) => {
      state.loadingHtml = true
    })
    builder.addCase(putHTML.fulfilled, (state, { payload }) => {
      state.loadingHtml = false
      state.putCheck = payload
    })
    builder.addCase(putHTML.rejected, (state) => {
      state.loadingHtml = false
    })
  },
})
