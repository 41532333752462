import { AlienationMethodSlice } from './slice/alienation-method.slice'
import { Bankruptcy } from './slice/bankruptcy.slice'
import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import { AuthSlice } from './slice/auth.slice'
import { NewsSlice } from './slice/news.slice'
import { InitiationSlice } from './slice/initiation.slice'
import { BargainAdSlice } from './slice/bargainad.slice'
import { CourtCaseSlice } from './slice/court-case.slice'
import { HTLMSlice } from './slice/html.slice'
import { LocalitySlice } from './slice/locality.slice'
import { FaqSlice } from './slice/faq.slice'
import { SpecAdminStatusSlice } from './slice/spec-admin-status'
import { PositionSlice } from './slice/position.slice'
import { ActivityTypeSlice } from './slice/activity-type.slice'
import { ArticleSlice } from './slice/article.slice'
import { ContactSlice } from './slice/contact.slice'
import { UserSlice } from './slice/user.slice'
import { LegalFormSlice } from './slice/legal-form.slice'
import { OwnSlice } from './slice/own.slice'
import { InitiationStatusSlice } from './slice/initiation-status.slice'
import { NoteSlice } from './slice/note.slice'
import { KuratorSlice } from './slice/kurator.slice'
import { FileSlice } from './slice/file.slice'
import { SpecAdmin } from './slice/spec-admin.slice'
import { SpecAdminTypeSlice } from './slice/spec-admin-type.slice'
import { LegalPersonSlice } from './slice/legal-person.slice'
import { NaturalPersonSlice } from './slice/natural-person.slice'
import { Form1Slice } from './slice/form-1.slice'
import { Form2Slice } from './slice/form-2.slice'
import { Form5Slice } from './slice/form-5.slice'
import { BankruptcyTypeSlice } from './slice/bankruptcy-type.slice'
import { BankruptcyStatusSlice } from './slice/bankruptcy-status.slice'
import { LocalStateSlice } from './slice/localState.slice'
import { Form3Slice } from './slice/form-3.slice'
import { Form4Slice } from './slice/form-4.slice'
import { StatisticSlice } from './slice/statistic.slice'
import { CourtCaseActSlice } from './slice/court-case-act.slice'
import { BankruptcyReportSlice } from './slice/bankruptcy-report.slice'
import { AnticoruptionSlice } from './slice/anticorruption-department.slice'
import { AuctionSlice } from './slice/auction.slice'

export const store = configureStore({
  reducer: {
    faq: FaqSlice.reducer,
    own: OwnSlice.reducer,
    file: FileSlice.reducer,
    html: HTLMSlice.reducer,
    news: NewsSlice.reducer,
    auth: AuthSlice.reducer,
    note: NoteSlice.reducer,
    users: UserSlice.reducer,
    form1: Form1Slice.reducer,
    form2: Form2Slice.reducer,
    form3: Form3Slice.reducer,
    form4: Form4Slice.reducer,
    form5: Form5Slice.reducer,
    specAdmin: SpecAdmin.reducer,
    auction: AuctionSlice.reducer,
    article: ArticleSlice.reducer,
    contact: ContactSlice.reducer,
    kurator: KuratorSlice.reducer,
    bankruptcy: Bankruptcy.reducer,
    locality: LocalitySlice.reducer,
    position: PositionSlice.reducer,
    legalForm: LegalFormSlice.reducer,
    courtCase: CourtCaseSlice.reducer,
    bargainAd: BargainAdSlice.reducer,
    statistics: StatisticSlice.reducer,
    initiation: InitiationSlice.reducer,
    localState: LocalStateSlice.reducer,
    legalPerson: LegalPersonSlice.reducer,
    courtCaseAct: CourtCaseActSlice.reducer,
    activityType: ActivityTypeSlice.reducer,
    specAdminType: SpecAdminTypeSlice.reducer,
    anticoruption: AnticoruptionSlice.reducer,
    naturalPerson: NaturalPersonSlice.reducer,
    bankruptcyType: BankruptcyTypeSlice.reducer,
    specAdminStatus: SpecAdminStatusSlice.reducer,
    alienationMethod: AlienationMethodSlice.reducer,
    initiationStatus: InitiationStatusSlice.reducer,
    bankruptcyStatus: BankruptcyStatusSlice.reducer,
    bankruptcyReportAdmins: BankruptcyReportSlice.reducer,
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
