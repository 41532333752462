import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from '../../api/axios-config'
import toast from 'react-hot-toast'

export const AnticoruptionGetAll = createAsyncThunk(
  'get/Anticoruption/all',
  async (
    {
      page,
      size,
      lang,
      desc,
      active,
    }: {
      page: number
      size: number
      lang: string
      desc: string
      active: boolean | null
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.get(
        `anticorruption-department/full?page=${page}&pageSize=${size}` +
          `${lang !== '' && lang !== undefined ? `&lang=${lang}` : ''}` +
          `${
            active !== undefined && active !== null ? `&active=${active}` : ''
          }` +
          `${desc !== '' && desc !== undefined ? `&desc=${desc}` : ''}`
      )
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const AnticoruptionGetSimplfield = createAsyncThunk(
  'get/Anticoruption/simplefield',
  async (
    { page, size, lang }: { page: number; size: number; lang: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.get(
        `anticorruption-department/simplefield/${page}/${size}/${lang}`
      )
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const AnticoruptionGetItem = createAsyncThunk(
  'get/Anticoruptioninner',
  async ({ id }: { id: number }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `anticorruption-department/only/${id}`
      )
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const PostAnticoruption = createAsyncThunk(
  'post/Anticoruption',
  async (body: IPostData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        'anticorruption-department',
        body
      )
      response && toast.success('Успешно добавлено !')
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

export const UpdateAnticoruption = createAsyncThunk(
  'put/Anticoruption',
  async (
    { body, id }: { body: IPostData; id: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.put(
        `anticorruption-department/${id}`,
        body
      )
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

export const DeleteAnticoruption = createAsyncThunk(
  'put/delete',
  async ({ id }: { id: number }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(
        `anticorruption-department/${id}`
      )
      console.log(response)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

interface IPostData {
  title: string
  desc: string
  active: boolean
  lang: string
  date: string
  content: string | null
}

interface IInitialState {
  loadingAnticoruption: boolean

  MainAnticoruptionData: null | {
    totalCount: number
    page: {
      active: boolean
      date: string
      desc: string
      id: number
      lang: string
      list: any
      title: string
      content: string
    }[]
  }
  InnerAnticoruptionData: {
    id: number
    title: string
    desc: string
    active: boolean
    date: null
    lang: string
    list: []
    content: string
  } | null
  SimpleAnticoruptionData: {
    page: {
      id: number
      title: string
      desc: string
      active: boolean
      date: null
      lang: string
      content: string
    }[]
    totalCount: number
  } | null
  fetchCheck: boolean
}

const initialState: IInitialState = {
  SimpleAnticoruptionData: null,
  MainAnticoruptionData: null,
  InnerAnticoruptionData: null,
  loadingAnticoruption: false,

  fetchCheck: false,
}

export const AnticoruptionSlice = createSlice({
  name: 'Anticoruption',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(AnticoruptionGetAll.pending, (state) => {
      state.loadingAnticoruption = true
    })
    builder.addCase(AnticoruptionGetAll.fulfilled, (state, { payload }) => {
      state.loadingAnticoruption = false
      state.MainAnticoruptionData = payload
    })
    builder.addCase(AnticoruptionGetAll.rejected, (state) => {
      state.loadingAnticoruption = false
    })
    ////////////////////////////////////
    builder.addCase(AnticoruptionGetSimplfield.pending, (state) => {
      state.loadingAnticoruption = true
    })
    builder.addCase(
      AnticoruptionGetSimplfield.fulfilled,
      (state, { payload }) => {
        state.loadingAnticoruption = false
        state.SimpleAnticoruptionData = payload
      }
    )
    builder.addCase(AnticoruptionGetSimplfield.rejected, (state) => {
      state.loadingAnticoruption = false
    })
    //--------------------
    builder.addCase(AnticoruptionGetItem.pending, (state) => {
      state.loadingAnticoruption = true
    })
    builder.addCase(AnticoruptionGetItem.fulfilled, (state, { payload }) => {
      state.loadingAnticoruption = false
      state.InnerAnticoruptionData = payload
    })
    builder.addCase(AnticoruptionGetItem.rejected, (state) => {
      state.loadingAnticoruption = false
    })
    //--------------------
    builder.addCase(PostAnticoruption.pending, (state) => {
      state.loadingAnticoruption = true
    })
    builder.addCase(PostAnticoruption.fulfilled, (state) => {
      state.loadingAnticoruption = false
      state.fetchCheck = !state.fetchCheck
    })
    builder.addCase(PostAnticoruption.rejected, (state) => {
      state.loadingAnticoruption = false
    })
    //--------------------
    builder.addCase(UpdateAnticoruption.pending, (state) => {
      state.loadingAnticoruption = true
    })
    builder.addCase(UpdateAnticoruption.fulfilled, (state) => {
      state.loadingAnticoruption = false
      state.fetchCheck = !state.fetchCheck
    })
    builder.addCase(UpdateAnticoruption.rejected, (state) => {
      state.loadingAnticoruption = false
    })
    //--------------------
    builder.addCase(DeleteAnticoruption.pending, (state) => {
      state.loadingAnticoruption = true
    })
    builder.addCase(DeleteAnticoruption.fulfilled, (state) => {
      state.loadingAnticoruption = false
      state.fetchCheck = !state.fetchCheck
    })
    builder.addCase(DeleteAnticoruption.rejected, (state) => {
      state.loadingAnticoruption = false
    })
  },
})
