import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from '../../api/axios-config'
import toast from 'react-hot-toast'

export const getUsers = createAsyncThunk(
  'get/users',
  async (
    {
      status,
      role,
      fullName,
    }: { status?: string; role?: string; fullName?: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.get(`users`, {
        params: {
          status,
          role,
          fullName,
        },
      })
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const postUsers = createAsyncThunk(
  'post/user',
  async (body: postData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`users`, body)
      console.log(response)
      toast.success('Успешно создано !')

      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

export const putUsers = createAsyncThunk(
  'put/user',
  async ({ body }: { body: putData }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`users`, body)
      toast.success('Успешно Изменено !')
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

export const getUserSingle = createAsyncThunk(
  'get/user/single',
  async (username: string, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`users/public/${username}`)
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

export const changePassword = createAsyncThunk(
  'put/user/password',
  async (
    {
      data,
    }: {
      data: {
        username: string
        password: string
        newPassword: string
      }
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.put(`users/change-password`, data)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

interface putData {
  id: number
  username: string
  status: string
  name: string
  surname: string
  patronymic: string
  role: string
  password: string | undefined
}
interface postData {
  username: string
  status: string
  name: string
  surname: string
  patronymic: string
  role: string
  password: string | null
}

interface data {
  id: number
  username: string
  status: string
  name: string
  surname: string
  patronymic: string
  role: string
  createdAt: string
  updatedAt: string
}

interface IinitialState {
  usersData: data[] | null
  loadingUser: boolean
  singleUser: any
  fetchCheck: any
}

const initialState: IinitialState = {
  usersData: null,
  loadingUser: false,
  singleUser: undefined,
  fetchCheck: null,
}

export const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(changePassword.pending, (state) => {
      state.loadingUser = true
    })
    builder.addCase(changePassword.fulfilled, (state) => {
      state.loadingUser = false
      state.fetchCheck = !state.fetchCheck
    })
    builder.addCase(changePassword.rejected, (state) => {
      state.loadingUser = false
    })
    //--------------------
    builder.addCase(getUserSingle.pending, (state) => {
      state.loadingUser = true
    })
    builder.addCase(getUserSingle.fulfilled, (state, { payload }) => {
      state.loadingUser = false
      state.singleUser = payload
    })
    builder.addCase(getUserSingle.rejected, (state) => {
      state.loadingUser = false
    })
    //--------------------
    builder.addCase(getUsers.pending, (state) => {
      state.loadingUser = true
    })
    builder.addCase(getUsers.fulfilled, (state, { payload }) => {
      state.loadingUser = false
      state.usersData = payload
    })
    builder.addCase(getUsers.rejected, (state) => {
      state.loadingUser = false
    })
    //--------------------
    builder.addCase(postUsers.pending, (state) => {
      state.loadingUser = true
    })
    builder.addCase(postUsers.fulfilled, (state, { payload }) => {
      state.loadingUser = false
      state.fetchCheck = payload
    })
    builder.addCase(postUsers.rejected, (state) => {
      state.loadingUser = false
    })
    //--------------------
    builder.addCase(putUsers.pending, (state) => {
      state.loadingUser = true
    })
    builder.addCase(putUsers.fulfilled, (state, { payload }) => {
      state.loadingUser = false
      state.fetchCheck = payload
    })
    builder.addCase(putUsers.rejected, (state) => {
      state.loadingUser = false
    })
  },
})
