import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from '../../api/axios-config'
import toast from 'react-hot-toast'

export const getAlienationMethodSimple = createAsyncThunk(
  'get/AlienationMethod/simple',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`alienation-method/simple`)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const getAlienationMethod = createAsyncThunk(
  'get/AlienationMethod',
  async (
    { page, pageSize }: { page: number; pageSize: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.get(
        `alienation-method/${page}/${pageSize}`
      )
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const postAlienationMethod = createAsyncThunk(
  'post/AlienationMethod',
  async (body: postData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`alienation-method`, body)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

export const putAlienationMethod = createAsyncThunk(
  'put/AlienationMethod',
  async ({ id, body }: { id: number; body: postData }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`alienation-method/${id}`, body)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

export const removeAlienationMethod = createAsyncThunk(
  'remove/AlienationMethod',
  async ({ id }: { id: any }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`alienation-method/${id}`)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error('Возможно этот элемент используется')
      rejectWithValue((error as Error).message)
    }
  }
)

interface postData {
  ruName: string
  kgName: string
  active: boolean | null
}
interface data {
  id: number
  ruName: string
  kgName: string
  active: boolean | null
}

interface IInitialState {
  loadingAlienationMethod: boolean
  AlienationMethodData: {
    page: data[]
    totalCount: number
  } | null
  fetchCheck: any
  simpleData: any
}

const initialState: IInitialState = {
  loadingAlienationMethod: false,
  AlienationMethodData: null,
  fetchCheck: null,
  simpleData: null,
}

export const AlienationMethodSlice = createSlice({
  name: 'AlienationMethod',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getAlienationMethodSimple.pending, (state) => {
      state.loadingAlienationMethod = true
    })
    builder.addCase(
      getAlienationMethodSimple.fulfilled,
      (state, { payload }) => {
        state.loadingAlienationMethod = false
        state.simpleData = payload
      }
    )
    builder.addCase(getAlienationMethodSimple.rejected, (state) => {
      state.loadingAlienationMethod = false
    })
    //--------------------
    builder.addCase(getAlienationMethod.pending, (state) => {
      state.loadingAlienationMethod = true
    })
    builder.addCase(getAlienationMethod.fulfilled, (state, { payload }) => {
      state.loadingAlienationMethod = false
      state.AlienationMethodData = payload
    })
    builder.addCase(getAlienationMethod.rejected, (state) => {
      state.loadingAlienationMethod = false
    })
    //--------------------
    builder.addCase(postAlienationMethod.pending, (state) => {
      state.loadingAlienationMethod = true
    })
    builder.addCase(postAlienationMethod.fulfilled, (state, { payload }) => {
      state.loadingAlienationMethod = false
      state.fetchCheck = payload
    })
    builder.addCase(postAlienationMethod.rejected, (state) => {
      state.loadingAlienationMethod = false
    })
    //--------------------
    builder.addCase(putAlienationMethod.pending, (state) => {
      state.loadingAlienationMethod = true
    })
    builder.addCase(putAlienationMethod.fulfilled, (state, { payload }) => {
      state.loadingAlienationMethod = false
      state.fetchCheck = payload
    })
    builder.addCase(putAlienationMethod.rejected, (state) => {
      state.loadingAlienationMethod = false
    })
    //--------------------
    builder.addCase(removeAlienationMethod.pending, (state) => {
      state.loadingAlienationMethod = true
    })
    builder.addCase(removeAlienationMethod.fulfilled, (state, { payload }) => {
      state.loadingAlienationMethod = false
      state.fetchCheck = payload
    })
    builder.addCase(removeAlienationMethod.rejected, (state) => {
      state.loadingAlienationMethod = false
    })
  },
})
