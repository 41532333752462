import { createSlice } from '@reduxjs/toolkit'

interface IInitialState {
  panelModal: boolean
  numbers: number[] | undefined
}

const initialState: IInitialState = {
  panelModal: false,
  numbers: undefined,
}

export const LocalStateSlice = createSlice({
  name: 'article',
  initialState,
  reducers: {
    setPanelModal: (state, { payload }) => {
      state.panelModal = payload
    },
    setNumbers: (state, { payload }) => {
      state.numbers = payload
    },
  },
})
export const { actions, reducer } = LocalStateSlice
export const { setPanelModal, setNumbers } = actions
