import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from '../../api/axios-config'
import toast from 'react-hot-toast'

export const getPosition = createAsyncThunk(
  'get/position',
  async (
    { page, pageSize }: { page: number; pageSize: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.get(`position/${page}/${pageSize}`)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)
export const getPositionSimple = createAsyncThunk(
  'get/position/simple',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`position/simple`)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)
export const getPositionByType = createAsyncThunk(
  'get/position/byType',
  async (type: string, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`position/ByType?type=${type}`)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const postPosition = createAsyncThunk(
  'post/position',
  async (body: postData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`position`, body)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

export const putPosition = createAsyncThunk(
  'put/position',
  async ({ id, body }: { id: number; body: postData }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`position/${id}`, body)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

export const removePosition = createAsyncThunk(
  'remove/position',
  async ({ id }: any, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`position/${id}`)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

interface postData {
  ruName: string | null
  kgName: string | null
  active: boolean | null
  type: string | null
}
interface data {
  id: number
  ruName: string | null
  kgName: string | null
  active: boolean | null
  type: string | null
}

interface IInitialState {
  loadingPosition: boolean
  positionDataType: data[] | null
  positionData: {
    page: data[]
    totalCount: number
  } | null
  fetchCheck: any
  simpleDataPosition: any
}

const initialState: IInitialState = {
  loadingPosition: false,
  positionDataType: null,
  positionData: null,
  fetchCheck: null,
  simpleDataPosition: null,
}

export const PositionSlice = createSlice({
  name: 'position',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getPositionSimple.pending, (state) => {
      state.loadingPosition = true
    })
    builder.addCase(getPositionSimple.fulfilled, (state, { payload }) => {
      state.loadingPosition = false
      state.simpleDataPosition = payload
    })
    builder.addCase(getPositionSimple.rejected, (state) => {
      state.loadingPosition = false
    })
    //--------------------
    builder.addCase(getPositionByType.pending, (state) => {
      state.loadingPosition = true
    })
    builder.addCase(getPositionByType.fulfilled, (state, { payload }) => {
      state.loadingPosition = false
      state.positionDataType = payload
    })
    builder.addCase(getPositionByType.rejected, (state) => {
      state.loadingPosition = false
    })
    //--------------------
    builder.addCase(getPosition.pending, (state) => {
      state.loadingPosition = true
    })
    builder.addCase(getPosition.fulfilled, (state, { payload }) => {
      state.loadingPosition = false
      state.positionData = payload
    })
    builder.addCase(getPosition.rejected, (state) => {
      state.loadingPosition = false
    })
    //--------------------
    builder.addCase(postPosition.pending, (state) => {
      state.loadingPosition = true
    })
    builder.addCase(postPosition.fulfilled, (state, { payload }) => {
      state.loadingPosition = false
      state.fetchCheck = payload
    })
    builder.addCase(postPosition.rejected, (state) => {
      state.loadingPosition = false
    })
    //--------------------
    builder.addCase(putPosition.pending, (state) => {
      state.loadingPosition = true
    })
    builder.addCase(putPosition.fulfilled, (state, { payload }) => {
      state.loadingPosition = false
      state.fetchCheck = payload
    })
    builder.addCase(putPosition.rejected, (state) => {
      state.loadingPosition = false
    })
    //--------------------
    builder.addCase(removePosition.pending, (state) => {
      state.loadingPosition = true
    })
    builder.addCase(removePosition.fulfilled, (state, { payload }) => {
      state.loadingPosition = false
      state.fetchCheck = payload
    })
    builder.addCase(removePosition.rejected, (state) => {
      state.loadingPosition = false
    })
  },
})
