import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from '../../api/axios-config'

export const getSpecAdminsReportBankruptcy = createAsyncThunk(
  'get/admins',
  async (
    { page, pageSize }: { page: number; pageSize: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.get(
        `spec-admin/report-bankruptcy/${page}/${pageSize}`
      )
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)
export const getSectionsReportBankruptcy = createAsyncThunk(
  'get/sections',
  async (ids: number[] | undefined, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`bankruptcy/sections-report`, {
        ids,
      })
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

interface IInitialState {
  loadingBankruptcyReport: boolean
  specAdminsDataReport: any
  sectionsDataReport: any
}

const initialState: IInitialState = {
  loadingBankruptcyReport: false,
  specAdminsDataReport: undefined,
  sectionsDataReport: undefined,
}

export const BankruptcyReportSlice = createSlice({
  name: 'bankruptcy-report',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getSectionsReportBankruptcy.pending, (state) => {
      state.loadingBankruptcyReport = true
    })
    builder.addCase(
      getSectionsReportBankruptcy.fulfilled,
      (state, { payload }) => {
        state.loadingBankruptcyReport = false
        state.sectionsDataReport = payload
      }
    )
    builder.addCase(getSectionsReportBankruptcy.rejected, (state) => {
      state.loadingBankruptcyReport = false
    })
    //------------------------------------------------------------------
    builder.addCase(getSpecAdminsReportBankruptcy.pending, (state) => {
      state.loadingBankruptcyReport = true
    })
    builder.addCase(
      getSpecAdminsReportBankruptcy.fulfilled,
      (state, { payload }) => {
        state.loadingBankruptcyReport = false
        state.specAdminsDataReport = payload
      }
    )
    builder.addCase(getSpecAdminsReportBankruptcy.rejected, (state) => {
      state.loadingBankruptcyReport = false
    })
    //------------------------------------------------------------------
  },
})
