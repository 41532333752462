import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from '../../api/axios-config'
import toast from 'react-hot-toast'

interface ICertificate {
  registrationNumber: string | null
  issueDate: string | undefined
  series: string | null
  number: string | null
  code: string | null
  legalFormId: number | undefined
  activityTypeId: number | undefined
  primaryRegistrationDate: string | undefined
  lastReRegistrationDate: string | undefined
  legalPersonId: number | undefined
  active: boolean
}

interface ILicenseData {
  statusId: number | undefined
  dateAssignment: string | undefined
  blankNumber: string | null
  dateEnd: string | undefined
  shortInformation: string
  file: any
  documentTypeId: number | undefined
}

interface ISpecData {
  surname: string
  name: string
  patronymic: string | null
  legalAddress: {
    leafId: number | null
    streetName: string
    house: string | null
    number: string | null
    index: string | null
  }
  actualAddress: {
    leafId: number | null
    streetName: string
    house: string | null
    number: string | null
    index: string | null
  }
  dateOfBirth: string | undefined
  dateOfExpiry: string | undefined
  dateOfIssue: string | undefined
  authority: string | null
  personalNumber: string | null
  documentNumber: string | null
  passportScanId: number | null
  phoneNumber: string | null
  email1: string | null
  email2: string | null
  certificates: ICertificate[] | null
  license: {
    registrationNumber: string
    date: string
    licensees: ILicenseData[] | null
  } | null
}

export const specAdminGetAll = createAsyncThunk(
  'get/specadmin/all',
  async (
    {
      page,
      pageSize,
      active,
      localityIds,
      fullName,
      personalNumber,
      statusIds,
    }: {
      page: number
      pageSize: number
      active?: boolean | undefined
      localityIds?: number[] | undefined
      fullName?: string | undefined
      personalNumber?: string | undefined
      statusIds?: number[] | undefined
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(
        `spec-admin/get/${page}/${pageSize}`,
        {
          active,
          localityIds,
          fullName,
          personalNumber,
          statusIds,
        }
      )
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const specAdminPost = createAsyncThunk(
  'post/specadmin/create',
  async (body: ISpecData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`spec-admin`, body)
      toast.success(response.data)
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

export const getSpecAdminById = createAsyncThunk(
  'get/specAdmin/byId',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`spec-admin/${id}`)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const getSpecAdminReport = createAsyncThunk(
  'get/specAdmin/report',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`spec-admin/report`)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const specAdminPut = createAsyncThunk(
  'post/specadmin/update',
  async (
    { data, id }: { data: ISpecData; id: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.put(`spec-admin/${id}`, data)

      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

export const specAdminDelete = createAsyncThunk(
  'post/specadmin/delete',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`spec-admin/${id}`)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const getSpecAdminSimple = createAsyncThunk(
  'get/specadmin/simple',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`spec-admin/simple`)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

interface IInitialState {
  simpeData: any
  loadingSpecAdmin: boolean
  fetchCheck: any
  specSingleData: any
  dataSpecAdmin:
    | {
        page: any
        totalCount: number | undefined
      }
    | undefined
  statisticLicensees: any
}

const initialState: IInitialState = {
  loadingSpecAdmin: false,
  dataSpecAdmin: {
    totalCount: undefined,
    page: undefined,
  },
  fetchCheck: false,
  simpeData: undefined,
  specSingleData: null,
  statisticLicensees: undefined,
}

export const SpecAdmin = createSlice({
  name: 'spec-admin',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getSpecAdminReport.pending, (state) => {
      state.loadingSpecAdmin = true
    })
    builder.addCase(getSpecAdminReport.fulfilled, (state, { payload }) => {
      state.loadingSpecAdmin = false
      state.statisticLicensees = payload
    })
    builder.addCase(getSpecAdminReport.rejected, (state) => {
      state.loadingSpecAdmin = false
    })
    //===========================================================
    builder.addCase(getSpecAdminById.pending, (state) => {
      state.loadingSpecAdmin = true
    })
    builder.addCase(getSpecAdminById.fulfilled, (state, { payload }) => {
      state.loadingSpecAdmin = false
      state.specSingleData = payload
    })
    builder.addCase(getSpecAdminById.rejected, (state) => {
      state.loadingSpecAdmin = false
    })
    //===========================================================
    builder.addCase(specAdminGetAll.pending, (state) => {
      state.loadingSpecAdmin = true
    })
    builder.addCase(specAdminGetAll.fulfilled, (state, { payload }) => {
      state.loadingSpecAdmin = false
      state.dataSpecAdmin = payload
    })
    builder.addCase(specAdminGetAll.rejected, (state) => {
      state.loadingSpecAdmin = false
    })
    //===========================================================
    builder.addCase(specAdminPost.pending, (state) => {
      state.loadingSpecAdmin = true
    })
    builder.addCase(specAdminPost.fulfilled, (state) => {
      state.loadingSpecAdmin = false
      state.fetchCheck = !state.fetchCheck
    })
    builder.addCase(specAdminPost.rejected, (state) => {
      state.loadingSpecAdmin = false
      state.fetchCheck = !state.fetchCheck
    })
    //===========================================================
    builder.addCase(specAdminPut.pending, (state) => {
      state.loadingSpecAdmin = true
    })
    builder.addCase(specAdminPut.fulfilled, (state) => {
      state.loadingSpecAdmin = false
      state.fetchCheck = !state.fetchCheck
    })
    builder.addCase(specAdminPut.rejected, (state) => {
      state.loadingSpecAdmin = false
      state.fetchCheck = !state.fetchCheck
    })
    //===========================================================
    builder.addCase(specAdminDelete.pending, (state) => {
      state.loadingSpecAdmin = true
    })
    builder.addCase(specAdminDelete.fulfilled, (state) => {
      state.loadingSpecAdmin = false
      state.fetchCheck = !state.fetchCheck
    })
    builder.addCase(specAdminDelete.rejected, (state) => {
      state.loadingSpecAdmin = false
    })
    //===========================================================
    builder.addCase(getSpecAdminSimple.pending, (state) => {
      state.loadingSpecAdmin = true
    })
    builder.addCase(getSpecAdminSimple.fulfilled, (state, { payload }) => {
      state.loadingSpecAdmin = false
      state.simpeData = payload
    })
    builder.addCase(getSpecAdminSimple.rejected, (state) => {
      state.loadingSpecAdmin = false
    })
  },
})
