import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from '../../api/axios-config'
import toast from 'react-hot-toast'

interface IForm4 {
  bankruptcyId: number
  quarter: number
  year: number
  capitalizationOffset: number | null
  capitalizationRecognize: number | null
  capitalizationRedemption: number | null
  capitalizationTotalRepaid: number | null
  capitalizationTransfer: number | null
  capitalizationTransferProperty: number | null
  queue2SalaryOffset: number | null
  queue2SalaryRecognized: number | null
  queue2SalaryRedemption: number | null
  queue2SalaryTotalRepaid: number | null
  queue2SalaryTransfer: number | null
  queue2SalaryTransferOfProperty: number | null
  queue2SocialFundOffset: number | null
  queue2SocialFundRecognized: number | null
  queue2SocialFundRedemption: number | null
  queue2SocialFundTotalRepaid: number | null
  queue2SocialFundTransfer: number | null
  queue2SocialFundTransferOfProperty: number | null
  sectionId: number | null
  creditors: IForm4Creditors[] | null
  q3: IForm4Q3[]
  q4: IForm4Q4[] | null
  q5: IForm4Q5[] | null
}

interface IForm4Creditors {
  form4Id: number
  name: string | null
  offset: number | null
  recognized: number | null
  redemption: number | null
  totalRepaid: number | null
  transfer: number | null
  transferOfProperty: number | null
}

interface IForm4Q3 {
  form4Id: number
  name: string | null
  offset: number | null
  recognized: number | null
  redemption: number | null
  totalRepaid: number | null
  transfer: number | null
  transferOfProperty: number | null
}

interface IForm4Q4 {
  form4Id: number
  name: string | null
  offset: number | null
  recognized: number | null
  redemption: number | null
  totalRepaid: number | null
  transfer: number | null
  transferOfProperty: number | null
}

interface IForm4Q5 {
  form4Id: number
  name: string | null
  offset: number | null
  recognized: number | null
  redemption: number | null
  totalRepaid: number | null
  transfer: number | null
  transferOfProperty: number | null
}

export const getForm4 = createAsyncThunk(
  'get/Form4',
  async (
    {
      page,
      pageSize,
      years,
      bankruptcyIds,
      statusIds,
      quarter,
      localityIds,
    }: {
      page: number
      pageSize: number
      bankruptcyIds: number[] | undefined
      years: number[] | undefined
      statusIds: number[] | undefined
      quarter: number[] | undefined
      localityIds: number[] | undefined
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(
        `form-4/get/${page}/${pageSize}`,
        {
          years,
          bankruptcyIds,
          statusIds,
          quarter,
          localityIds,
        }
      )
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const getForm4ById = createAsyncThunk(
  'get/Form4/byId',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`form-4/${id}`)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const postForm4 = createAsyncThunk(
  'post/Form4',
  async (data: IForm4, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`form-4`, data)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

export const putForm4 = createAsyncThunk(
  'put/Form4',
  async ({ data, id }: { data: IForm4; id: number }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`form-4/${id}`, data)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

export const deleteForm4 = createAsyncThunk(
  'delete/Form4',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`form-4/${id}`)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

interface IInitialState {
  loadingForm4: boolean
  fetchCheck: any
  Form4Data: any
  singleData: any
}

const initialState: IInitialState = {
  loadingForm4: false,
  fetchCheck: null,
  Form4Data: null,
  singleData: undefined,
}

export const Form4Slice = createSlice({
  name: 'Form4',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getForm4ById.pending, (state) => {
      state.loadingForm4 = true
    })
    builder.addCase(getForm4ById.fulfilled, (state, { payload }) => {
      state.loadingForm4 = false
      state.singleData = payload
    })
    builder.addCase(getForm4ById.rejected, (state) => {
      state.loadingForm4 = false
    })
    //--------------------
    builder.addCase(getForm4.pending, (state) => {
      state.loadingForm4 = true
    })
    builder.addCase(getForm4.fulfilled, (state, { payload }) => {
      state.loadingForm4 = false
      state.Form4Data = payload
    })
    builder.addCase(getForm4.rejected, (state) => {
      state.loadingForm4 = false
    })
    //--------------------
    builder.addCase(postForm4.pending, (state) => {
      state.loadingForm4 = true
    })
    builder.addCase(postForm4.fulfilled, (state) => {
      state.loadingForm4 = false
      state.fetchCheck = !state.fetchCheck
    })
    builder.addCase(postForm4.rejected, (state) => {
      state.loadingForm4 = false
      state.fetchCheck = !state.fetchCheck
    })
    //--------------------
    builder.addCase(putForm4.pending, (state) => {
      state.loadingForm4 = true
    })
    builder.addCase(putForm4.fulfilled, (state) => {
      state.loadingForm4 = false
      state.fetchCheck = !state.fetchCheck
    })
    builder.addCase(putForm4.rejected, (state) => {
      state.loadingForm4 = false
      state.fetchCheck = !state.fetchCheck
    })
    //--------------------
    builder.addCase(deleteForm4.pending, (state) => {
      state.loadingForm4 = true
    })
    builder.addCase(deleteForm4.fulfilled, (state) => {
      state.loadingForm4 = false
      state.fetchCheck = !state.fetchCheck
    })
    builder.addCase(deleteForm4.rejected, (state) => {
      state.loadingForm4 = false
      state.fetchCheck = !state.fetchCheck
    })
    //--------------------
  },
})
