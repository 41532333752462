import { memo } from 'react'
import gerb from '../../../../assets/image/kgz_gerb.webp'
import './style.css'

export const GerbLoader = memo(() => {
  return (
    <div className='back_loader'>
      <div className='background_blur'></div>
      <div className='loader'>
        <div className='wrapper_image'>
          <img
            src={gerb}
            alt='gerb'
            className='image_gerb'
            width={120}
            height={120}
          />
          <span className='shine'></span>
        </div>
        <span className='line'></span>
      </div>
    </div>
  )
})
