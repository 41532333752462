import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from '../../api/axios-config'

export const getBankruptcyStatus = createAsyncThunk(
  'get/BankruptcyStatus',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`bankruptcy-status`)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

interface IInitialState {
  loadingBankruptcyStatus: boolean
  BankruptcyStatusData: any
  fetchCheck: any
}

const initialState: IInitialState = {
  loadingBankruptcyStatus: false,
  BankruptcyStatusData: null,
  fetchCheck: null,
}

export const BankruptcyStatusSlice = createSlice({
  name: 'BankruptcyStatus',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getBankruptcyStatus.pending, (state) => {
      state.loadingBankruptcyStatus = true
    })
    builder.addCase(getBankruptcyStatus.fulfilled, (state, { payload }) => {
      state.loadingBankruptcyStatus = false
      state.BankruptcyStatusData = payload
    })
    builder.addCase(getBankruptcyStatus.rejected, (state) => {
      state.loadingBankruptcyStatus = false
    })
    //--------------------
  },
})
