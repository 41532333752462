import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from '../../api/axios-config'
import toast from 'react-hot-toast'

export interface INaturalPerson {
  active: boolean
  legalAddress: {
    leafId: number | null
    streetName: string
    house: string | null
    number: string | null
    index: string | null
  }
  actualAddress: {
    leafId: number | null
    streetName: string
    house: string | null
    number: string | null
    index: string | null
  }
  dateOfBirth: string | null
  dateOfExpiry: string | null
  dateOfIssue: string | null
  authority: string | null
  passportScanId: null | number
  personalNumber: string | null
  phoneNumber: null | string
  documentNumber: string | null
  email1: null | string
  email2: null | string
  fileId: null | number
  surname: string
  name: string
  patronymic: string | null
  tin: string
  registrationCertificates: IregistrationCertificate[] | null
}

interface IregistrationCertificate {
  typeId: number
  legalFormId: number
  legalPersonId: number
  active: boolean
  code: string
  dateIssue: string
  lastDateRegistration: string | null
  legalPersonCode: string
  number: string
  primaryDateRegistration: string
  registrationNumber: string
  series: string
  taxDecision: string
  taxDecisionDate: string
}

export const getNaturalPersonDirectory = createAsyncThunk(
  'get/NaturalPerson/directory',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`natural-person/directory`)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const getNaturalPerson = createAsyncThunk(
  'get/NaturalPerson',
  async (
    {
      page,
      pageSize,
      active,
      fullName,
      tin,
      localityIds,
    }: {
      page: number
      pageSize: number
      active: boolean | undefined
      fullName: string | undefined
      tin: string | undefined
      localityIds: number[] | undefined
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(
        `natural-person/get/${page}/${pageSize}`,
        {
          active,
          fullName,
          tin,
          localityIds,
        }
      )
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const getNaturalPersonSingle = createAsyncThunk(
  'get/NaturalPerson/single',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`natural-person/${id}`)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const postNaturalPerson = createAsyncThunk(
  'post/NaturalPerson',
  async (data: INaturalPerson, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`natural-person`, data)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

export const putNaturalPerson = createAsyncThunk(
  'put/NaturalPerson',
  async (
    { data, id }: { data: INaturalPerson; id: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.put(`natural-person/${id}`, data)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

export const deleteNaturalPerson = createAsyncThunk(
  'delete/NaturalPerson',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`natural-person/${id}`)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

interface IInitialState {
  loadingNaturalPerson: boolean
  fetchCheck: any
  NaturalPersonDataTax: any
  NaturalPersonDataDirectory: any
  NaturalPersonData: any
  NaturalPersonSingleData: any
}

const initialState: IInitialState = {
  NaturalPersonDataTax: null,
  NaturalPersonDataDirectory: null,
  loadingNaturalPerson: false,
  fetchCheck: null,
  NaturalPersonData: null,
  NaturalPersonSingleData: null,
}

export const NaturalPersonSlice = createSlice({
  name: 'NaturalPerson',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getNaturalPersonSingle.pending, (state) => {
      state.loadingNaturalPerson = true
    })
    builder.addCase(getNaturalPersonSingle.fulfilled, (state, { payload }) => {
      state.loadingNaturalPerson = false
      state.NaturalPersonSingleData = payload
    })
    builder.addCase(getNaturalPersonSingle.rejected, (state) => {
      state.loadingNaturalPerson = false
    })
    //--------------------
    builder.addCase(getNaturalPersonDirectory.pending, (state) => {
      state.loadingNaturalPerson = true
    })
    builder.addCase(
      getNaturalPersonDirectory.fulfilled,
      (state, { payload }) => {
        state.loadingNaturalPerson = false
        state.NaturalPersonDataDirectory = payload
      }
    )
    builder.addCase(getNaturalPersonDirectory.rejected, (state) => {
      state.loadingNaturalPerson = false
    })
    //--------------------
    builder.addCase(getNaturalPerson.pending, (state) => {
      state.loadingNaturalPerson = true
    })
    builder.addCase(getNaturalPerson.fulfilled, (state, { payload }) => {
      state.loadingNaturalPerson = false
      state.NaturalPersonData = payload
    })
    builder.addCase(getNaturalPerson.rejected, (state) => {
      state.loadingNaturalPerson = false
    })
    //--------------------
    builder.addCase(postNaturalPerson.pending, (state) => {
      state.loadingNaturalPerson = true
    })
    builder.addCase(postNaturalPerson.fulfilled, (state) => {
      state.loadingNaturalPerson = false
      state.fetchCheck = !state.fetchCheck
    })
    builder.addCase(postNaturalPerson.rejected, (state) => {
      state.loadingNaturalPerson = false
      state.fetchCheck = !state.fetchCheck
    })
    //--------------------
    builder.addCase(putNaturalPerson.pending, (state) => {
      state.loadingNaturalPerson = true
    })
    builder.addCase(putNaturalPerson.fulfilled, (state) => {
      state.loadingNaturalPerson = false
      state.fetchCheck = !state.fetchCheck
    })
    builder.addCase(putNaturalPerson.rejected, (state) => {
      state.loadingNaturalPerson = false
      state.fetchCheck = !state.fetchCheck
    })
    //--------------------
    builder.addCase(deleteNaturalPerson.pending, (state) => {
      state.loadingNaturalPerson = true
    })
    builder.addCase(deleteNaturalPerson.fulfilled, (state) => {
      state.loadingNaturalPerson = false
      state.fetchCheck = !state.fetchCheck
    })
    builder.addCase(deleteNaturalPerson.rejected, (state) => {
      state.loadingNaturalPerson = false
      state.fetchCheck = !state.fetchCheck
    })
    //--------------------
  },
})
