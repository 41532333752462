import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from '../../api/axios-config'
import toast from 'react-hot-toast'

export const getKuratorActive = createAsyncThunk(
  'get/Kurator/active',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`kurator/active`)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const getKurator = createAsyncThunk(
  'get/Kurator',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`kurator`)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const postKurator = createAsyncThunk(
  'post/kurator',
  async (body: postData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`kurator`, body)
      toast.success('Успешно создано !')

      return response.data
    } catch (error) {
      console.log(error)

      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

export const putKurator = createAsyncThunk(
  'put/kurator',
  async ({ body, id }: { body: postData; id: number }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`kurator/${id}`, body)
      toast.success('Успешно Изменено !')
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

export const removeKurator = createAsyncThunk(
  'remove/kurator',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`kurator/${id}`)
      toast.success('Успешно Изменено !')
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

interface IPostion {
  id: number
  ruName: string
  kgName: string | null
  active: boolean
  type: string
}

interface postData {
  positionId: number | null
  name: string
  surname: string
  patronymic: string
  active: boolean
}

interface data {
  id: number
  position: IPostion
  fullName: string
  name: string
  surname: string
  patronymic: string
  active: boolean
}

interface IinitialState {
  KuratorData: data[] | null
  KuratorDataActive: any
  loadingKurator: boolean
  fetchCheck: any
}

const initialState: IinitialState = {
  KuratorData: null,
  KuratorDataActive: null,
  loadingKurator: false,
  fetchCheck: null,
}

export const KuratorSlice = createSlice({
  name: 'kurator',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getKuratorActive.pending, (state) => {
      state.loadingKurator = true
    })
    builder.addCase(getKuratorActive.fulfilled, (state, { payload }) => {
      state.loadingKurator = false
      state.KuratorDataActive = payload
    })
    builder.addCase(getKuratorActive.rejected, (state) => {
      state.loadingKurator = false
    })
    //--------------------
    builder.addCase(getKurator.pending, (state) => {
      state.loadingKurator = true
    })
    builder.addCase(getKurator.fulfilled, (state, { payload }) => {
      state.loadingKurator = false
      state.KuratorData = payload
    })
    builder.addCase(getKurator.rejected, (state) => {
      state.loadingKurator = false
    })
    //--------------------
    builder.addCase(postKurator.pending, (state) => {
      state.loadingKurator = true
    })
    builder.addCase(postKurator.fulfilled, (state, { payload }) => {
      state.loadingKurator = false
      state.fetchCheck = payload
    })
    builder.addCase(postKurator.rejected, (state) => {
      state.loadingKurator = false
    })
    //--------------------
    builder.addCase(putKurator.pending, (state) => {
      state.loadingKurator = true
    })
    builder.addCase(putKurator.fulfilled, (state, { payload }) => {
      state.loadingKurator = false
      state.fetchCheck = payload
    })
    builder.addCase(putKurator.rejected, (state) => {
      state.loadingKurator = false
    })
    //--------------------
    builder.addCase(removeKurator.pending, (state) => {
      state.loadingKurator = true
    })
    builder.addCase(removeKurator.fulfilled, (state, { payload }) => {
      state.loadingKurator = false
      state.fetchCheck = payload
    })
    builder.addCase(removeKurator.rejected, (state) => {
      state.loadingKurator = false
    })
  },
})
