import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from '../../api/axios-config'

export const getBankruptcyType = createAsyncThunk(
  'get/BankruptcyType',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`bankruptcy/type`)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

interface IInitialState {
  loadingBankruptcyType: boolean
  BankruptcyTypeData: any
  fetchCheck: any
}

const initialState: IInitialState = {
  loadingBankruptcyType: false,
  BankruptcyTypeData: null,
  fetchCheck: null,
}

export const BankruptcyTypeSlice = createSlice({
  name: 'BankruptcyType',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getBankruptcyType.pending, (state) => {
      state.loadingBankruptcyType = true
    })
    builder.addCase(getBankruptcyType.fulfilled, (state, { payload }) => {
      state.loadingBankruptcyType = false
      state.BankruptcyTypeData = payload
    })
    builder.addCase(getBankruptcyType.rejected, (state) => {
      state.loadingBankruptcyType = false
    })
    //--------------------
  },
})
