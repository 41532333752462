import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from '../../api/axios-config'
import toast from 'react-hot-toast'

export const getNoteSimple = createAsyncThunk(
  'get/Note/simple',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`note/simple`)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const getNote = createAsyncThunk(
  'get/Note',
  async (
    { page, pageSize }: { page: number; pageSize: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.get(`note/${page}/${pageSize}`)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const postNote = createAsyncThunk(
  'post/Note',
  async (body: postData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`note`, body)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

export const putNote = createAsyncThunk(
  'put/Note',
  async ({ id, body }: { id: number; body: postData }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`note/${id}`, body)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

export const removeNote = createAsyncThunk(
  'remove/Note',
  async ({ id }: { id: any }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`note/${id}`)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error('Возможно этот элемент используется')
      rejectWithValue((error as Error).message)
    }
  }
)

interface postData {
  ruName: string
  kgName: string
  active: boolean | null
}
interface data {
  id: number
  ruName: string
  kgName: string
  active: boolean | null
}

interface IInitialState {
  loadingNote: boolean
  NoteData: {
    page: data[]
    totalCount: number
  } | null
  fetchCheck: any
  simpleData: any
}

const initialState: IInitialState = {
  loadingNote: false,
  NoteData: null,
  fetchCheck: null,
  simpleData: null,
}

export const NoteSlice = createSlice({
  name: 'Note',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getNoteSimple.pending, (state) => {
      state.loadingNote = true
    })
    builder.addCase(getNoteSimple.fulfilled, (state, { payload }) => {
      state.loadingNote = false
      state.simpleData = payload
    })
    builder.addCase(getNoteSimple.rejected, (state) => {
      state.loadingNote = false
    })
    //--------------------
    builder.addCase(getNote.pending, (state) => {
      state.loadingNote = true
    })
    builder.addCase(getNote.fulfilled, (state, { payload }) => {
      state.loadingNote = false
      state.NoteData = payload
    })
    builder.addCase(getNote.rejected, (state) => {
      state.loadingNote = false
    })
    //--------------------
    builder.addCase(postNote.pending, (state) => {
      state.loadingNote = true
    })
    builder.addCase(postNote.fulfilled, (state, { payload }) => {
      state.loadingNote = false
      state.fetchCheck = payload
    })
    builder.addCase(postNote.rejected, (state) => {
      state.loadingNote = false
    })
    //--------------------
    builder.addCase(putNote.pending, (state) => {
      state.loadingNote = true
    })
    builder.addCase(putNote.fulfilled, (state, { payload }) => {
      state.loadingNote = false
      state.fetchCheck = payload
    })
    builder.addCase(putNote.rejected, (state) => {
      state.loadingNote = false
    })
    //--------------------
    builder.addCase(removeNote.pending, (state) => {
      state.loadingNote = true
    })
    builder.addCase(removeNote.fulfilled, (state, { payload }) => {
      state.loadingNote = false
      state.fetchCheck = payload
    })
    builder.addCase(removeNote.rejected, (state) => {
      state.loadingNote = false
    })
  },
})
