import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from '../../api/axios-config'
import toast from 'react-hot-toast'

export const getFaq = createAsyncThunk(
  'get/Faq',
  async (
    {
      page,
      pageSize,
      lang,
      text,
    }: { page: number; pageSize: number; lang?: string; text?: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.get(
        `faq?page=${page}&pageSize=${pageSize}` +
          `${lang !== '' && lang !== undefined ? `&lang=${lang}` : ''}` +
          `${text !== '' && text !== undefined ? `&text=${text}` : ''}`
      )
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const postFaq = createAsyncThunk(
  'post/Faq',
  async (body: postData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`faq`, body)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

export const putFaq = createAsyncThunk(
  'put/Faq',
  async ({ id, body }: { id: number; body: postData }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`faq/${id}`, body)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

export const removeFaq = createAsyncThunk(
  'remove/Faq',
  async ({ id }: { id: number }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`faq/${id}`)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

interface postData {
  question: string | null
  answer: string | null
  lang: string | null
}
interface data {
  id: number
  question: string
  answer: string
  lang: string
}

interface IInitialState {
  loadingFaq: boolean
  faqData: {
    totalCount: number
    page: data[] | []
  } | null
  deleteCheck: any
  postCheck: any
  putCheck: any
}

const initialState: IInitialState = {
  loadingFaq: false,
  faqData: null,
  deleteCheck: null,
  postCheck: null,
  putCheck: null,
}

export const FaqSlice = createSlice({
  name: 'faq',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getFaq.pending, (state) => {
      state.loadingFaq = true
    })
    builder.addCase(getFaq.fulfilled, (state, { payload }) => {
      state.loadingFaq = false
      state.faqData = payload
    })
    builder.addCase(getFaq.rejected, (state) => {
      state.loadingFaq = false
    })
    //--------------------
    builder.addCase(postFaq.pending, (state) => {
      state.loadingFaq = true
    })
    builder.addCase(postFaq.fulfilled, (state, { payload }) => {
      state.loadingFaq = false
      state.postCheck = payload
    })
    builder.addCase(postFaq.rejected, (state) => {
      state.loadingFaq = false
    })
    //--------------------
    builder.addCase(putFaq.pending, (state) => {
      state.loadingFaq = true
    })
    builder.addCase(putFaq.fulfilled, (state, { payload }) => {
      state.loadingFaq = false
      state.putCheck = payload
    })
    builder.addCase(putFaq.rejected, (state) => {
      state.loadingFaq = false
    })
    //--------------------
    builder.addCase(removeFaq.pending, (state) => {
      state.loadingFaq = true
    })
    builder.addCase(removeFaq.fulfilled, (state, { payload }) => {
      state.loadingFaq = false
      state.deleteCheck = payload
    })
    builder.addCase(removeFaq.rejected, (state) => {
      state.loadingFaq = false
    })
  },
})
