import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from '../../api/axios-config'
import toast from 'react-hot-toast'

export const GetArticleId = createAsyncThunk(
  'get/article',
  async ({ id }: { id: number }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`article/only/${id}`)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const GetAllArticle = createAsyncThunk(
  'get/article/all',
  async (
    {
      page,
      pageSize,
      type,
      active,
      desc,
      lang,
    }: {
      type: string
      page: number
      pageSize: number
      active: boolean | null
      desc: string
      lang: string
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.get(
        `article/full?page=${page}&pageSize=${pageSize}` +
          `${type !== '' && type !== undefined ? `&type=${type}` : ''}` +
          `${
            active !== undefined && active !== null ? `&active=${active}` : ''
          }` +
          `${desc !== '' && desc !== undefined ? `&desc=${desc}` : ''}` +
          `${lang !== '' && lang !== undefined ? `&lang=${lang}` : ''}`
      )
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const GetArticleSimple = createAsyncThunk(
  'get/simple/all',
  async (
    {
      type,
      page,
      pageSize,
      lang,
    }: { type: string; page: number; pageSize: number; lang?: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.get(
        `article/${type}/${page}/${pageSize}/${lang && lang}`
      )
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const PostArticle = createAsyncThunk(
  'post/article',
  async ({ body }: { body: postData }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`article`, body)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

export const PutArticle = createAsyncThunk(
  'put/article',
  async ({ id, body }: { id: number; body: postData }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`article/${id}`, body)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

export const DeleteArticle = createAsyncThunk(
  'delete/article',
  async ({ id }: { id: number }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`article/${id}`)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

export const getArticleById = createAsyncThunk(
  'get/article/id',
  async ({ id }: { id: string | number }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`article/only/${id}`)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

interface postData {
  active: boolean
  date: string
  desc: string
  lang: string
  title: string
  type: string
  content: string | null
  file: any | null
}
interface data {
  id: number
  active: boolean
  date: string
  desc: string
  lang: string
  title: string
  type: string
  content: string
  file: any | null
}
interface dataSimple {
  id: number
  active: boolean
  date: string
  desc: string
  lang: string
  title: string
  type: string
  file: any | null
}

interface IInitialState {
  ArticleData: data | null
  listData: {
    totalCount: number
    page: data[] | []
  } | null
  loadingArticle: boolean
  PutArticleCheck: any
  PostArticleCheck: any
  DeleteArtilceCheck: any
  ArticleSimpleData: {
    totalCount: number
    page: dataSimple[]
  } | null
  oneData: data | null
}

const initialState: IInitialState = {
  ArticleData: null,
  loadingArticle: false,
  listData: null,
  PutArticleCheck: null,
  PostArticleCheck: null,
  DeleteArtilceCheck: null,
  ArticleSimpleData: null,
  oneData: null,
}

export const ArticleSlice = createSlice({
  name: 'article',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getArticleById.pending, (state) => {
      state.loadingArticle = true
    })
    builder.addCase(getArticleById.fulfilled, (state, { payload }) => {
      state.loadingArticle = false
      state.oneData = payload
    })
    builder.addCase(getArticleById.rejected, (state) => {
      state.loadingArticle = false
    })
    //////////////////////////////////////
    builder.addCase(GetArticleSimple.pending, (state) => {
      state.loadingArticle = true
    })
    builder.addCase(GetArticleSimple.fulfilled, (state, { payload }) => {
      state.loadingArticle = false
      state.ArticleSimpleData = payload
    })
    builder.addCase(GetArticleSimple.rejected, (state) => {
      state.loadingArticle = false
    })
    //////////////////////////////////////
    builder.addCase(GetArticleId.pending, (state) => {
      state.loadingArticle = true
    })
    builder.addCase(GetArticleId.fulfilled, (state, { payload }) => {
      state.loadingArticle = false
      state.ArticleData = payload
    })
    builder.addCase(GetArticleId.rejected, (state) => {
      state.loadingArticle = false
    })
    //////////////////////////////////////
    builder.addCase(GetAllArticle.pending, (state) => {
      state.loadingArticle = true
    })
    builder.addCase(GetAllArticle.fulfilled, (state, { payload }) => {
      state.loadingArticle = false
      state.listData = payload
    })
    builder.addCase(GetAllArticle.rejected, (state) => {
      state.loadingArticle = false
    })
    //////////////////////////////////////
    builder.addCase(PostArticle.pending, (state) => {
      state.loadingArticle = true
    })
    builder.addCase(PostArticle.fulfilled, (state, { payload }) => {
      state.loadingArticle = false
      state.PostArticleCheck = payload
    })
    builder.addCase(PostArticle.rejected, (state) => {
      state.loadingArticle = false
    })
    //////////////////////////////////////
    builder.addCase(PutArticle.pending, (state) => {
      state.loadingArticle = true
    })
    builder.addCase(PutArticle.fulfilled, (state, { payload }) => {
      state.loadingArticle = false
      state.PutArticleCheck = payload
    })
    builder.addCase(PutArticle.rejected, (state) => {
      state.loadingArticle = false
    })
    //////////////////////////////////////
    builder.addCase(DeleteArticle.pending, (state) => {
      state.loadingArticle = true
    })
    builder.addCase(DeleteArticle.fulfilled, (state, { payload }) => {
      state.loadingArticle = false
      state.DeleteArtilceCheck = payload
    })
    builder.addCase(DeleteArticle.rejected, (state) => {
      state.loadingArticle = false
    })
  },
})
