import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from '../../api/axios-config'
import toast from 'react-hot-toast'

export interface IStateAuction {
  id: number | null
  name: string
  title: string
  active: boolean
  address: string
  text: string
  file: any
  date: string | null
  event: string | null
  localityId: number | null
}

export const AuctionGetAll = createAsyncThunk(
  'get/Auction/all',
  async (
    {
      page,
      size,
    }: {
      page: number
      size: number
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.get(`auction/${page}/${size}`)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const AuctionGetSimplfield = createAsyncThunk(
  'get/auction/simplefield',
  async (
    { page, size }: { page: number; size: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.get(
        `auction/simplefield/${page}/${size}`
      )
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const AuctionGetItem = createAsyncThunk(
  'get/Auction/inner',
  async ({ id }: { id: number }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`auction/only/${id}`)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const PostAuction = createAsyncThunk(
  'post/auction',
  async (body: IPostData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('auction', body)
      toast.success('Успешно добавлено !')
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

export const UpdateAuction = createAsyncThunk(
  'put/auction',
  async (
    { body, id }: { body: IPostData; id: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.put(`auction/${id}`, body)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

export const DeleteAuction = createAsyncThunk(
  'auction/delete',
  async ({ id }: { id: number }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`auction/${id}`)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
      toast.error((error as Error).message)
    }
  }
)

interface IPostData {
  name: string
  event: string | null
  title: string
  date: string | null
  active: boolean
  address: string
  localityId: number | null
  text: string | null
  fileId: number | null
}

interface IInitialState {
  loadingAuction: boolean
  MainAuctionData: any
  InnerAuctionData: any
  SimpleAuctionData: any
  fetchCheck: boolean
}

const initialState: IInitialState = {
  SimpleAuctionData: null,
  MainAuctionData: null,
  InnerAuctionData: null,
  loadingAuction: false,
  fetchCheck: false,
}

export const AuctionSlice = createSlice({
  name: 'Auction',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(AuctionGetAll.pending, (state) => {
      state.loadingAuction = true
    })
    builder.addCase(AuctionGetAll.fulfilled, (state, { payload }) => {
      state.loadingAuction = false
      state.MainAuctionData = payload
    })
    builder.addCase(AuctionGetAll.rejected, (state) => {
      state.loadingAuction = false
    })
    ////////////////////////////////////
    builder.addCase(AuctionGetSimplfield.pending, (state) => {
      state.loadingAuction = true
    })
    builder.addCase(AuctionGetSimplfield.fulfilled, (state, { payload }) => {
      state.loadingAuction = false
      state.SimpleAuctionData = payload
    })
    builder.addCase(AuctionGetSimplfield.rejected, (state) => {
      state.loadingAuction = false
    })
    //--------------------
    builder.addCase(AuctionGetItem.pending, (state) => {
      state.loadingAuction = true
    })
    builder.addCase(AuctionGetItem.fulfilled, (state, { payload }) => {
      state.loadingAuction = false
      state.InnerAuctionData = payload
    })
    builder.addCase(AuctionGetItem.rejected, (state) => {
      state.loadingAuction = false
    })
    //--------------------
    builder.addCase(PostAuction.pending, (state) => {
      state.loadingAuction = true
    })
    builder.addCase(PostAuction.fulfilled, (state) => {
      state.loadingAuction = false
      state.fetchCheck = !state.fetchCheck
    })
    builder.addCase(PostAuction.rejected, (state) => {
      state.loadingAuction = false
    })
    //--------------------
    builder.addCase(UpdateAuction.pending, (state) => {
      state.loadingAuction = true
    })
    builder.addCase(UpdateAuction.fulfilled, (state) => {
      state.loadingAuction = false
      state.fetchCheck = !state.fetchCheck
    })
    builder.addCase(UpdateAuction.rejected, (state) => {
      state.loadingAuction = false
    })
    //--------------------
    builder.addCase(DeleteAuction.pending, (state) => {
      state.loadingAuction = true
    })
    builder.addCase(DeleteAuction.fulfilled, (state) => {
      state.loadingAuction = false
      state.fetchCheck = !state.fetchCheck
    })
    builder.addCase(DeleteAuction.rejected, (state) => {
      state.loadingAuction = false
    })
  },
})
