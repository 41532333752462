import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from '../../api/axios-config'
import toast from 'react-hot-toast'

interface IBankruptcy {
  typeOfProcedureMethod: string | null
  personType: string | null
  legalPersonId: number | null
  naturalPersonId: number | null
  statusId: number | null
  typeOfProcedureId: number | null
  date: string | null
  section1: ISection1
}

///sections

interface ISection1 {
  typeOfProcedureMeetingDate: string | null
  typeOfProcedureMethod: string | null
  renewalDate: string | null
  courtSolutionDate: string | null
  courtSolutionLegalPersonId: number | null
  courtSolutionNumber: string | null
  courtSolutionFileId: number | null
  creditorsMeetingDate: string | null
  creditorsMeetingNumber: number | null
  typeOfProcedureCourtSolutionDate: string | null
  typeOfProcedureCourtSolutionLegalPersonId: number | null
  typeOfProcedureCourtSolutionNumber: string | null
  kuratorId: number | null
  statusId: number | null
  creditorsMeetingFileId: number | null
  typeOfProcedureId: number | null
  typeOfProcedureCourtSolutionFileId: number | null
  initiators: Section1Initiators[] | null
  restructurings: Section1TypeProcedureRestructResst[] | null
}

interface Section1Initiators {
  fullName: string | null
  number: number | null
  personType: string | null
}

interface Section1TypeProcedureRestructResst {
  averageOfEmployees: number | null
  capital: number | null
  legalPersonId: number | null
  meetinngDate: string | null
}

interface ISection2 {
  courtSolutionDate: string | null
  courtSolutionLegalPersonId: number | null
  courtSolutionNumber: string | null
  note: string | null
  terminationDate: string | null
  courtSolutionFileId: number | null
  kuratorId: number | null
  statusId: number | null
}

interface ISection3 {
  courtSolutionDate: string | null
  courtSolutionLegalPersonId: number | null
  courtSolutionNumber: string | null
  courtSolutionFileId: number | null
  creditorsMeetingDate: string | null
  creditorsMeetingNumber: number | null
  typeOfProcedureCourtSolutionDate: string | null
  typeOfProcedureCourtSolutionLegalPersonId: number | null
  typeOfProcedureCourtSolutionNumber: string | null
  typeOfProcedureMeetingDate: string | null
  kuratorId: number | null
  statusId: number | null
  creditorsMeetingFileId: number | null
  typeOfProcedureId: number | null
  typeOfProcedureCourtSolutionFileId: number | null
  initiators: ISection3Initiators[] | null
  restructurings: ISection3TypeProcedureRestructRest[] | null
  renewalDate: string | null
  typeOfProcedureMethod: string | null
}

interface ISection3Initiators {
  fullName: string
  number: number
  personType: string
}

interface ISection3TypeProcedureRestructRest {
  averageOfEmployees: number
  capital: number
  legalPersonId: number
  meetinngDate: string
}

interface ISection4 {
  courtSolutionDate: string | null
  courtSolutionLegalPersonId: number | null
  courtSolutionNumber: string | null
  courtSolutionFileId: number | null
  creditorsMeetingDate: string | null
  creditorsMeetingNumber: number | null
  creditorsMeetingFileId: number | null
  kuratorId: number | null
  statusId: number | null
  date: string | null
  number: string | null
  fileId: number | null
}

interface IBankruptcySectionsSpecAdmins {
  orderEndDate: string | null
  orderEndNumber: string | null
  orderEndFileId: number | null
  specAdminId: number | null
  orderStartDate: string | null
  orderStartNumber: string | null
  orderStartFileId: number | null
  positionId: number | null
  sectionsId: number | null
}

export const getBankruptcyAllProcces = createAsyncThunk(
  'get/bankruptcy/allProcess',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get('bankruptcy/all-process')
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const getBankruptcySections = createAsyncThunk(
  'get/bankruptcy/sections',
  async (bankruptcyId: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `bankruptcy/sections/${bankruptcyId}`
      )
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const getBankruptcy = createAsyncThunk(
  'get/bankruptcy',
  async (
    {
      page,
      pageSize,
      personType,
      fullName,
      tin,
      localityIds,
      statusIds,
    }: {
      page: number
      pageSize: number
      personType: string | undefined
      fullName: string | undefined
      tin: string | undefined
      localityIds: number[] | undefined
      statusIds: number[] | undefined
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(
        `bankruptcy/get/${page}/${pageSize}`,
        {
          personType,
          fullName,
          tin,
          localityIds,
          statusIds,
        }
      )
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const getBankruptcyById = createAsyncThunk(
  'get/bankruptcy/byId',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`bankruptcy/${id}`)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const postBankruptcy = createAsyncThunk(
  'post/bankruptcy',
  async (data: IBankruptcy, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`bankruptcy`, data)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const postSection1 = createAsyncThunk(
  'post/section1',
  async (
    { data, bankruptcyId }: { data: ISection1; bankruptcyId: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(
        `bankruptcy/section1/${bankruptcyId}`,
        data
      )
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
      toast.error('Не получилось изменить !')
    }
  }
)

export const putSection1 = createAsyncThunk(
  'put/section1',
  async (
    { data, sectionId }: { data: ISection1; sectionId: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.put(
        `bankruptcy/section1/${sectionId}`,
        data
      )
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
      toast.error('Не получилось изменить !')
    }
  }
)

export const deleteSection1 = createAsyncThunk(
  'delete/section1',
  async (section1Id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(
        `bankruptcy/section1/${section1Id}`
      )
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
      toast.error('Не получилось удалить !')
    }
  }
)

export const postSection2 = createAsyncThunk(
  'post/section2',
  async (
    { data, bankruptcyId }: { data: ISection2; bankruptcyId: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(
        `bankruptcy/section2/${bankruptcyId}`,
        data
      )
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
      toast.error('Не получилось добавить !')
    }
  }
)

export const putSection2 = createAsyncThunk(
  'put/section2',
  async (
    { data, sectionsId }: { data: ISection2; sectionsId: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.put(
        `bankruptcy/section2/${sectionsId}`,
        data
      )
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
      toast.error('Не получилось изменить !')
    }
  }
)

export const deleteSection2 = createAsyncThunk(
  'delete/section2',
  async (sectionsId: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(
        `bankruptcy/section2/${sectionsId}`
      )
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
      toast.error('Не получилось удалить !')
    }
  }
)

export const postSection3 = createAsyncThunk(
  'post/section3',
  async (
    { data, bankruptcyId }: { data: ISection3; bankruptcyId: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(
        `bankruptcy/section3/${bankruptcyId}`,
        data
      )
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const putSection3 = createAsyncThunk(
  'put/section3',
  async (
    { data, sectionsId }: { data: ISection3; sectionsId: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.put(
        `bankruptcy/section3/${sectionsId}`,
        data
      )
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const deleteSection3 = createAsyncThunk(
  'delete/section3',
  async (sectionsId: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(
        `bankruptcy/section3/${sectionsId}`
      )
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
      toast.error('Не получилось удалить !')
    }
  }
)

export const postSection4 = createAsyncThunk(
  'post/section4',
  async (
    { data, bankruptcyId }: { data: ISection4; bankruptcyId: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(
        `bankruptcy/section4/${bankruptcyId}`,
        data
      )
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
      toast.error('Не получилось добавить !')
    }
  }
)

export const putSection4 = createAsyncThunk(
  'put/section4',
  async (
    { data, sectionsId }: { data: ISection4; sectionsId: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.put(
        `bankruptcy/section4/${sectionsId}`,
        data
      )
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
      toast.error('Не получилось изменить !')
    }
  }
)

export const deleteSection4 = createAsyncThunk(
  'delete/section4',
  async (sectionsId: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(
        `bankruptcy/section4/${sectionsId}`
      )
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
      toast.error('Не получилось удалить !')
    }
  }
)

export const postBankruptcySpecAdmin = createAsyncThunk(
  'post/bankruptcy/specadmin',
  async (data: IBankruptcySectionsSpecAdmins, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`bankruptcy/spec-admin`, data)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
      toast.error('Не получилось добавить !')
    }
  }
)

export const putBankruptcySpecAdmin = createAsyncThunk(
  'put/bankruptcy/specadmin',
  async (
    { data, id }: { data: IBankruptcySectionsSpecAdmins; id: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.put(
        `bankruptcy/spec-admin/${id}`,
        data
      )
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
      toast.error('Не получилось изменить !')
    }
  }
)

export const deleteBankruptcySpecAdmin = createAsyncThunk(
  'delete/bankruptcy/specadmin',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`bankruptcy/spec-admin/${id}`)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
      toast.error('Не получилось удалить !')
    }
  }
)

export const getBankruptcyPublic = createAsyncThunk(
  'get/bankruptcy/public',
  async (
    {
      page,
      pageSize,
      personType,
      fullName,
      tin,
      localityIds,
      statusIds,
    }: {
      page: number
      pageSize: number
      personType?: string | undefined
      fullName?: string | undefined
      tin?: string | undefined
      localityIds?: number[] | undefined
      statusIds?: number[] | undefined
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(
        `bankruptcy/get/public/${page}/${pageSize}`,
        {
          personType,
          fullName,
          tin,
          localityIds,
          statusIds,
        }
      )
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

interface IinitialState {
  bankruptcyCountData: null | {
    [key: string]: number
  }
  isLoadingBankruptcy: boolean
  BankData: any
  fetchCheck: boolean
  allProcessData: any
  SectionsData: any
  singleData: any
  publicData: any
}
const initialState: IinitialState = {
  bankruptcyCountData: null,
  BankData: null,
  isLoadingBankruptcy: false,
  fetchCheck: false,
  allProcessData: null,
  SectionsData: null,
  singleData: null,
  publicData: undefined,
}

export const Bankruptcy = createSlice({
  name: 'bankrupcy',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(postBankruptcy.pending, (state) => {
      state.isLoadingBankruptcy = true
    })
    builder.addCase(postBankruptcy.fulfilled, (state) => {
      state.isLoadingBankruptcy = false
      state.fetchCheck = !state.fetchCheck
    })
    builder.addCase(postBankruptcy.rejected, (state) => {
      state.isLoadingBankruptcy = false
    })
    //----------------------------
    builder.addCase(getBankruptcyPublic.pending, (state) => {
      state.isLoadingBankruptcy = true
    })
    builder.addCase(getBankruptcyPublic.fulfilled, (state, { payload }) => {
      state.isLoadingBankruptcy = false
      state.publicData = payload
      state.fetchCheck = !state.fetchCheck
    })
    builder.addCase(getBankruptcyPublic.rejected, (state) => {
      state.isLoadingBankruptcy = false
    })
    //----------------------------
    builder.addCase(postBankruptcySpecAdmin.pending, (state) => {
      state.isLoadingBankruptcy = true
    })
    builder.addCase(postBankruptcySpecAdmin.fulfilled, (state) => {
      state.isLoadingBankruptcy = false
      state.fetchCheck = !state.fetchCheck
    })
    builder.addCase(postBankruptcySpecAdmin.rejected, (state) => {
      state.isLoadingBankruptcy = false
    })
    //----------------------------
    builder.addCase(putBankruptcySpecAdmin.pending, (state) => {
      state.isLoadingBankruptcy = true
    })
    builder.addCase(putBankruptcySpecAdmin.fulfilled, (state) => {
      state.isLoadingBankruptcy = false
      state.fetchCheck = !state.fetchCheck
    })
    builder.addCase(putBankruptcySpecAdmin.rejected, (state) => {
      state.isLoadingBankruptcy = false
    })
    //----------------------------
    builder.addCase(deleteBankruptcySpecAdmin.pending, (state) => {
      state.isLoadingBankruptcy = true
    })
    builder.addCase(deleteBankruptcySpecAdmin.fulfilled, (state) => {
      state.isLoadingBankruptcy = false
      state.fetchCheck = !state.fetchCheck
    })
    builder.addCase(deleteBankruptcySpecAdmin.rejected, (state) => {
      state.isLoadingBankruptcy = false
    })
    //----------------------------
    builder.addCase(postSection1.pending, (state) => {
      state.isLoadingBankruptcy = true
    })
    builder.addCase(postSection1.fulfilled, (state) => {
      state.isLoadingBankruptcy = false
      state.fetchCheck = !state.fetchCheck
    })
    builder.addCase(postSection1.rejected, (state) => {
      state.isLoadingBankruptcy = false
    })
    //----------------------------
    builder.addCase(putSection1.pending, (state) => {
      state.isLoadingBankruptcy = true
    })
    builder.addCase(putSection1.fulfilled, (state) => {
      state.isLoadingBankruptcy = false
      state.fetchCheck = !state.fetchCheck
    })
    builder.addCase(putSection1.rejected, (state) => {
      state.isLoadingBankruptcy = false
    })
    //----------------------------
    builder.addCase(deleteSection1.pending, (state) => {
      state.isLoadingBankruptcy = true
    })
    builder.addCase(deleteSection1.fulfilled, (state) => {
      state.isLoadingBankruptcy = false
      state.fetchCheck = !state.fetchCheck
    })
    builder.addCase(deleteSection1.rejected, (state) => {
      state.isLoadingBankruptcy = false
    })
    //----------------------------
    builder.addCase(postSection2.pending, (state) => {
      state.isLoadingBankruptcy = true
    })
    builder.addCase(postSection2.fulfilled, (state) => {
      state.isLoadingBankruptcy = false
      state.fetchCheck = !state.fetchCheck
    })
    builder.addCase(postSection2.rejected, (state) => {
      state.isLoadingBankruptcy = false
    })
    //----------------------------
    builder.addCase(putSection2.pending, (state) => {
      state.isLoadingBankruptcy = true
    })
    builder.addCase(putSection2.fulfilled, (state) => {
      state.isLoadingBankruptcy = false
      state.fetchCheck = !state.fetchCheck
    })
    builder.addCase(putSection2.rejected, (state) => {
      state.isLoadingBankruptcy = false
    })
    //----------------------------
    builder.addCase(deleteSection2.pending, (state) => {
      state.isLoadingBankruptcy = true
    })
    builder.addCase(deleteSection2.fulfilled, (state) => {
      state.isLoadingBankruptcy = false
      state.fetchCheck = !state.fetchCheck
    })
    builder.addCase(deleteSection2.rejected, (state) => {
      state.isLoadingBankruptcy = false
    })
    //----------------------------
    builder.addCase(postSection3.pending, (state) => {
      state.isLoadingBankruptcy = true
    })
    builder.addCase(postSection3.fulfilled, (state) => {
      state.fetchCheck = !state.fetchCheck
      state.isLoadingBankruptcy = false
    })
    builder.addCase(postSection3.rejected, (state) => {
      state.isLoadingBankruptcy = false
    })
    //----------------------------
    builder.addCase(putSection3.pending, (state) => {
      state.isLoadingBankruptcy = true
    })
    builder.addCase(putSection3.fulfilled, (state) => {
      state.fetchCheck = !state.fetchCheck
      state.isLoadingBankruptcy = false
    })
    builder.addCase(putSection3.rejected, (state) => {
      state.isLoadingBankruptcy = false
    })
    //----------------------------
    builder.addCase(deleteSection3.pending, (state) => {
      state.isLoadingBankruptcy = true
    })
    builder.addCase(deleteSection3.fulfilled, (state) => {
      state.fetchCheck = !state.fetchCheck
      state.isLoadingBankruptcy = false
    })
    builder.addCase(deleteSection3.rejected, (state) => {
      state.isLoadingBankruptcy = false
    })
    //----------------------------
    builder.addCase(postSection4.pending, (state) => {
      state.isLoadingBankruptcy = true
    })
    builder.addCase(postSection4.fulfilled, (state) => {
      state.fetchCheck = !state.fetchCheck
      state.isLoadingBankruptcy = false
    })
    builder.addCase(postSection4.rejected, (state) => {
      state.isLoadingBankruptcy = false
    })
    //----------------------------
    builder.addCase(putSection4.pending, (state) => {
      state.isLoadingBankruptcy = true
    })
    builder.addCase(putSection4.fulfilled, (state) => {
      state.fetchCheck = !state.fetchCheck
      state.isLoadingBankruptcy = false
    })
    builder.addCase(putSection4.rejected, (state) => {
      state.isLoadingBankruptcy = false
    })
    //----------------------------
    builder.addCase(deleteSection4.pending, (state) => {
      state.isLoadingBankruptcy = true
    })
    builder.addCase(deleteSection4.fulfilled, (state) => {
      state.fetchCheck = !state.fetchCheck
      state.isLoadingBankruptcy = false
    })
    builder.addCase(deleteSection4.rejected, (state) => {
      state.isLoadingBankruptcy = false
    })
    //!! GET !!
    builder.addCase(getBankruptcySections.pending, (state) => {
      state.isLoadingBankruptcy = true
    })
    builder.addCase(getBankruptcySections.fulfilled, (state, { payload }) => {
      state.isLoadingBankruptcy = false
      state.SectionsData = payload
    })
    builder.addCase(getBankruptcySections.rejected, (state) => {
      state.isLoadingBankruptcy = false
    })
    //----------------------------
    builder.addCase(getBankruptcyAllProcces.pending, (state) => {
      state.isLoadingBankruptcy = true
    })
    builder.addCase(getBankruptcyAllProcces.fulfilled, (state, { payload }) => {
      state.isLoadingBankruptcy = false
      state.allProcessData = payload
    })
    builder.addCase(getBankruptcyAllProcces.rejected, (state) => {
      state.isLoadingBankruptcy = false
    })
    //----------------------------
    builder.addCase(getBankruptcy.pending, (state) => {
      state.isLoadingBankruptcy = true
    })
    builder.addCase(getBankruptcy.fulfilled, (state, { payload }) => {
      state.isLoadingBankruptcy = false
      state.BankData = payload
    })
    builder.addCase(getBankruptcy.rejected, (state) => {
      state.isLoadingBankruptcy = false
    })
    //-----------------------------------------------------------------
    builder.addCase(getBankruptcyById.pending, (state) => {
      state.isLoadingBankruptcy = true
    })
    builder.addCase(getBankruptcyById.fulfilled, (state, { payload }) => {
      state.isLoadingBankruptcy = false
      state.singleData = payload
    })
    builder.addCase(getBankruptcyById.rejected, (state) => {
      state.isLoadingBankruptcy = false
    })
    //-----------------------------------------------------------------
  },
})
