import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getCookie } from '../../components/function'
import { _KEY_AUTH } from '../../common/variables/variables'
import axiosInstance from '../../api/axios-config'
import toast from 'react-hot-toast'
import axios from 'axios'

export const Login = createAsyncThunk(
  'auth/login',
  async (UserData: IAuthorizationPost, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('auth/login', UserData)
      toast.success('Успешный вход !')
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toast.error(error.response?.data.message)
        return rejectWithValue((error as Error).message)
      }
    }
  }
)

export const Registration = createAsyncThunk(
  'auth/registration',
  async (signUpData: IRegistration, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('auth/registration', signUpData)
      toast.success('Успешная регистрация')
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toast.error(error.response?.data.message)
        return rejectWithValue((error as Error).message)
      }
    }
  }
)

export const EmailCheck = createAsyncThunk(
  'auth/email-check',
  async (content: string, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('email-check', content)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

interface IAuthorizationPost {
  username: string
  password: string
}

interface IRegistration {
  username: string
  name: string
  surname: string
  patronymic: string
  password: string
}

export interface IGetUser {
  id: number
  name: string
  surname: string
  patronymic: string
  username: string
  role: string
  status: string
  token: string
}

interface IinitialState {
  token: null | string
  username: string | null
  role:
    | null
    | 'ROLE_MODERATOR'
    | 'ROLE_LENSER'
    | 'ROLE_ADMIN'
    | 'ROLE_MANAGER'
    | 'ROLE_USER'
  authId: ''
  phoneNumber: ''
  check: any
  loading: boolean
  authUser: IGetUser | null
}

const cookieDataString = getCookie(_KEY_AUTH)
const convertObj = cookieDataString ? JSON.parse(cookieDataString) : {}
const initialState: IinitialState = {
  token: convertObj?.token || null,
  role: convertObj?.role || 'ROLE_LENSER',
  username: convertObj?.username || null,
  authId: '',
  phoneNumber: '',
  check: null,
  loading: false,
  authUser: null,
}

export const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsAuthenticated: (state) => {
      state.role = null
      state.token = null
    },
    setIsAuth: (state, { payload }) => {
      state.token = payload
    },
    setIsRole: (state, { payload }) => {
      state.role = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(Login.pending, (state) => {
      state.loading = true
    })
    builder.addCase(Login.fulfilled, (state, { payload }) => {
      state.loading = false
      state.authUser = payload
      state.role = payload.role
      state.token = payload.token
      state.username = payload.username
    })
    builder.addCase(Login.rejected, (state) => {
      state.loading = false
    })
    ////
    builder.addCase(Registration.pending, (state) => {
      state.loading = true
    })
    builder.addCase(Registration.fulfilled, (state, { payload }) => {
      state.loading = false
      state.authUser = payload
      state.role = payload.role
      state.token = payload.token
    })
    builder.addCase(Registration.rejected, (state) => {
      state.loading = false
    })
    ////
    builder.addCase(EmailCheck.fulfilled, (state, { payload }) => {
      state.check = payload
    })
  },
})

export const { setIsAuthenticated, setIsAuth, setIsRole } = AuthSlice.actions
