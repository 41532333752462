import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from '../../api/axios-config'
import toast from 'react-hot-toast'

export const getActivityType = createAsyncThunk(
  'get/ActivityType',
  async (
    { page, pageSize }: { page: number; pageSize: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.get(
        `activity-type/${page}/${pageSize}`
      )
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const getActivityTypeSimple = createAsyncThunk(
  'get/ActivityType/simple',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`activity-type/simple`)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const postActivityType = createAsyncThunk(
  'post/ActivityType',
  async (body: postData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`activity-type`, body)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.success((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

export const putActivityType = createAsyncThunk(
  'put/ActivityType',
  async ({ id, body }: { id: number; body: postData }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`activity-type/${id}`, body)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.success((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

export const removeActivityType = createAsyncThunk(
  'remove/ActivityType',
  async ({ id }: any, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`activity-type/${id}`)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error('Возможно этот элемент используется')
      rejectWithValue((error as Error).message)
    }
  }
)

interface postData {
  ruName: string | null
  kgName: string | null
  active: boolean
  classifier: string | null
  code: string | null
}
interface data {
  id: number
  ruName: string | null
  kgName: string | null
  active: boolean
  classifier: string | null
  code: string | null
}

interface IInitialState {
  loadingActivityType: boolean
  ActivityTypeData: {
    totalCount: number
    page: data[]
  } | null
  fetchCheck: any
  simpleData:
    | {
        id: number
        ruName: string
      }[]
    | null
}

const initialState: IInitialState = {
  loadingActivityType: false,
  ActivityTypeData: null,
  fetchCheck: null,
  simpleData: null,
}

export const ActivityTypeSlice = createSlice({
  name: 'activityType',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getActivityType.pending, (state) => {
      state.loadingActivityType = true
    })
    builder.addCase(getActivityType.fulfilled, (state, { payload }) => {
      state.loadingActivityType = false
      state.ActivityTypeData = payload
    })
    builder.addCase(getActivityType.rejected, (state) => {
      state.loadingActivityType = false
    })
    //--------------------
    builder.addCase(getActivityTypeSimple.pending, (state) => {
      state.loadingActivityType = true
    })
    builder.addCase(getActivityTypeSimple.fulfilled, (state, { payload }) => {
      state.loadingActivityType = false
      state.simpleData = payload
    })
    builder.addCase(getActivityTypeSimple.rejected, (state) => {
      state.loadingActivityType = false
    })
    //--------------------
    builder.addCase(postActivityType.pending, (state) => {
      state.loadingActivityType = true
    })
    builder.addCase(postActivityType.fulfilled, (state, { payload }) => {
      state.loadingActivityType = false
      state.fetchCheck = payload
    })
    builder.addCase(postActivityType.rejected, (state) => {
      state.loadingActivityType = false
    })
    //--------------------
    builder.addCase(putActivityType.pending, (state) => {
      state.loadingActivityType = true
    })
    builder.addCase(putActivityType.fulfilled, (state, { payload }) => {
      state.loadingActivityType = false
      state.fetchCheck = payload
    })
    builder.addCase(putActivityType.rejected, (state) => {
      state.loadingActivityType = false
    })
    //--------------------
    builder.addCase(removeActivityType.pending, (state) => {
      state.loadingActivityType = true
    })
    builder.addCase(removeActivityType.fulfilled, (state, { payload }) => {
      state.loadingActivityType = false
      state.fetchCheck = payload
    })
    builder.addCase(removeActivityType.rejected, (state) => {
      state.loadingActivityType = false
    })
  },
})
