import axios, { AxiosInstance } from 'axios'
import { _KEY_AUTH } from '../common/variables/variables'
import { getCookie } from '../components/function'

const hostname = window.location.hostname

let apiBaseURL

if (hostname === '91.207.28.131') {
  apiBaseURL = 'https://91.207.28.131/api'
} else if (hostname === 'bankrotstvo.kg' || hostname === 'www.bankrotstvo.kg') {
  apiBaseURL = 'https://www.bankrotstvo.kg/api'
} else {
  apiBaseURL = 'https://www.bankrotstvo.kg/api'
}

const axiosInstance: AxiosInstance = axios.create({
  baseURL: apiBaseURL,
  timeout: 5000,
})

axiosInstance.interceptors.request.use((config) => {
  const cookieDataString = getCookie(_KEY_AUTH)
  const convertObj = cookieDataString ? JSON.parse(cookieDataString) : {}

  if (convertObj.token) {
    config.headers.Authorization = `Bearer ${convertObj.token}`
  } else {
    config.headers.Authorization = ''
  }

  return config
})

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error)
  }
)

export default axiosInstance
