import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from '../../api/axios-config'

export interface Initiation {
  personType: string | null
  fullName: string | null
  personalNumber: string | null
  ownId: number | null
  debtAmount: any
  receiveDate: string | null
  type: string | null
  statusId: number | null
  initiators: Initiator[] | null
  initiationSections: InitiationSection[] | null
}

export interface Initiator {
  fullName: string | null
  phoneNumber: string | null
  leafId: number | null
  streetName: string | null
  house: string | null
  number: string | null
  index: string | null
}

export interface InitiationSection {
  departureDate: string | null
  actDate: string | null
  fileId: number | null
  information: string | null
  legalPersonId: number | null
}

export const InitiationGet = createAsyncThunk(
  'get/initiation',
  async (
    {
      page,
      pageSize,
      endDate,
      startDate,
      fullName,
      personalNumber,
      statusIds,
      typesIds,
    }: {
      page: number
      pageSize: number
      endDate: string | undefined
      startDate: string | undefined
      fullName: string | undefined
      personalNumber: string | undefined
      statusIds: number[] | undefined
      typesIds: string[] | undefined
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(
        `initiation/get/${page}/${pageSize}`,
        {
          endDate,
          startDate,
          fullName,
          personalNumber,
          statusIds,
          typesIds,
        }
      )
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const InitiationGetReport = createAsyncThunk(
  'get/initiation/report',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`initiation/report`)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const InitiationGetById = createAsyncThunk(
  'get/initiation/byId',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`initiation/${id}`)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)
export const InitiationPost = createAsyncThunk(
  'post/initiation',
  async (data: Initiation, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`initiation`, data)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const InitiationPut = createAsyncThunk(
  'put/initiation',
  async (
    { data, id }: { data: Initiation; id: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.put(`initiation/${id}`, data)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const InitiationDelete = createAsyncThunk(
  'delete/initiation',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`initiation/${id}`)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const InitiationGetPublic = createAsyncThunk(
  'get/initiation/public',
  async (
    {
      page,
      pageSize,
      date,
      fullName,
      personalNumber,
    }: {
      page: number
      pageSize: number
      date?: string | undefined
      fullName?: string | undefined
      personalNumber?: string | undefined
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(
        `initiation/get/public/${page}/${pageSize}`,
        {
          date,
          fullName,
          personalNumber,
        }
      )
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

interface IInitialState {
  MainInitiationData: null
  loadingInitiation: boolean
  initiationData: any
  checkFetch: boolean
  publicData: any
  singleData: any
  reportData: any
}

const initialState: IInitialState = {
  MainInitiationData: null,
  loadingInitiation: false,
  initiationData: null,
  checkFetch: false,
  publicData: undefined,
  singleData: undefined,
  reportData: undefined,
}

export const InitiationSlice = createSlice({
  name: 'initiation',
  initialState,
  reducers: {},
  extraReducers(builder) {
    ////////////////////////////////////////////////
    builder.addCase(InitiationGetReport.pending, (state) => {
      state.loadingInitiation = true
    })
    builder.addCase(InitiationGetReport.fulfilled, (state, { payload }) => {
      state.loadingInitiation = false
      state.reportData = payload
    })
    builder.addCase(InitiationGetReport.rejected, (state) => {
      state.loadingInitiation = false
    })
    ////////////////////////////////////////////////
    builder.addCase(InitiationGetById.pending, (state) => {
      state.loadingInitiation = true
    })
    builder.addCase(InitiationGetById.fulfilled, (state, { payload }) => {
      state.loadingInitiation = false
      state.singleData = payload
    })
    builder.addCase(InitiationGetById.rejected, (state) => {
      state.loadingInitiation = false
    })
    ////////////////////////////////////////////////
    builder.addCase(InitiationGetPublic.pending, (state) => {
      state.loadingInitiation = true
    })
    builder.addCase(InitiationGetPublic.fulfilled, (state, { payload }) => {
      state.loadingInitiation = false
      state.publicData = payload
    })
    builder.addCase(InitiationGetPublic.rejected, (state) => {
      state.loadingInitiation = false
    })
    ////////////////////////////////////////////////
    builder.addCase(InitiationGet.pending, (state) => {
      state.loadingInitiation = true
    })
    builder.addCase(InitiationGet.fulfilled, (state, { payload }) => {
      state.loadingInitiation = false
      state.initiationData = payload
    })
    builder.addCase(InitiationGet.rejected, (state) => {
      state.loadingInitiation = false
    })
    ///////////////////////////////////////////////
    builder.addCase(InitiationPost.pending, (state) => {
      state.loadingInitiation = true
    })
    builder.addCase(InitiationPost.fulfilled, (state) => {
      state.loadingInitiation = false
      state.checkFetch = !state.checkFetch
    })
    builder.addCase(InitiationPost.rejected, (state) => {
      state.loadingInitiation = false
      state.checkFetch = !state.checkFetch
    })
    ///////////////////////////////////////////////
    builder.addCase(InitiationPut.pending, (state) => {
      state.loadingInitiation = true
    })
    builder.addCase(InitiationPut.fulfilled, (state) => {
      state.loadingInitiation = false
      state.checkFetch = !state.checkFetch
    })
    builder.addCase(InitiationPut.rejected, (state) => {
      state.loadingInitiation = false
      state.checkFetch = !state.checkFetch
    })
    ///////////////////////////////////////////////
    builder.addCase(InitiationDelete.pending, (state) => {
      state.loadingInitiation = true
    })
    builder.addCase(InitiationDelete.fulfilled, (state) => {
      state.loadingInitiation = false
      state.checkFetch = !state.checkFetch
    })
    builder.addCase(InitiationDelete.rejected, (state) => {
      state.loadingInitiation = false
      state.checkFetch = !state.checkFetch
    })
    ///////////////////////////////////////////////
  },
})
