import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from '../../api/axios-config'

export const getSpecStatus = createAsyncThunk(
  'get/specAdminStatus',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`spec-admin-status`)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const postSpecStatus = createAsyncThunk(
  'post/specAdminStatus',
  async (body: postData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`spec-admin-status`, body)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const putSpecStatus = createAsyncThunk(
  'put/specAdminStatus',
  async ({ id, body }: { id: number; body: postData }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`spec-admin-status/${id}`, body)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const removeSpecStatus = createAsyncThunk(
  'remove/specAdminStatus',
  async ({ id }: any, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`spec-admin-status/${id}`)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

interface postData {
  ruName: string | null
  kgName: string | null
}
interface data {
  id: number
  ruName: string | null
  kgName: string | null
}

interface IInitialState {
  loadingSpecStatus: boolean
  specStatusData: data[] | null
}

const initialState: IInitialState = {
  loadingSpecStatus: false,
  specStatusData: null,
}

export const SpecAdminStatusSlice = createSlice({
  name: 'specAdminStatus',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getSpecStatus.pending, (state) => {
      state.loadingSpecStatus = true
    })
    builder.addCase(getSpecStatus.fulfilled, (state, { payload }) => {
      state.loadingSpecStatus = false
      state.specStatusData = payload
    })
    builder.addCase(getSpecStatus.rejected, (state) => {
      state.loadingSpecStatus = false
    })
    //--------------------
    builder.addCase(postSpecStatus.pending, (state) => {
      state.loadingSpecStatus = true
    })
    builder.addCase(postSpecStatus.fulfilled, (state) => {
      state.loadingSpecStatus = false
    })
    builder.addCase(postSpecStatus.rejected, (state) => {
      state.loadingSpecStatus = false
    })
    //--------------------
    builder.addCase(putSpecStatus.pending, (state) => {
      state.loadingSpecStatus = true
    })
    builder.addCase(putSpecStatus.fulfilled, (state) => {
      state.loadingSpecStatus = false
    })
    builder.addCase(putSpecStatus.rejected, (state) => {
      state.loadingSpecStatus = false
    })
    //--------------------
    builder.addCase(removeSpecStatus.pending, (state) => {
      state.loadingSpecStatus = true
    })
    builder.addCase(removeSpecStatus.fulfilled, (state) => {
      state.loadingSpecStatus = false
    })
    builder.addCase(removeSpecStatus.rejected, (state) => {
      state.loadingSpecStatus = false
    })
  },
})
