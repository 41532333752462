import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from '../../api/axios-config'

export const getByMethods = createAsyncThunk(
  'get/byMethods',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        'bankruptcy/public-counts-by-methods'
      )
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const getByStateShares = createAsyncThunk(
  'get/byStateShares',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        'bankruptcy/public-count-by-state-shares'
      )
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)
export const getByPeriod = createAsyncThunk(
  'get/byPeriod',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        'bankruptcy/public-count-by-period'
      )
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const getCountry = createAsyncThunk(
  'get/country',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('bankruptcy/country')
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const getOblast = createAsyncThunk(
  'get/oblast',
  async (oblast: string, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`bankruptcy/country/${oblast}`)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const getSearchData = createAsyncThunk(
  'get/search',
  async (text: string, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`search`, {
        text: text,
      })
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

interface IinitialState {
  loadingMap: boolean
  mapData: any
  loadingByMethods: boolean
  dataByMethods:
    | {
        liquidation: number
        restructuring: number
        individual: number
        reorganization: number
        rehabilitation: number
      }
    | undefined
  loadingByPeriod: boolean
  dataByPeriod:
    | {
        process: number
        end: number
      }
    | undefined
  loadingByStateShares: boolean
  dataByStateShares:
    | {
        legalPersons: number
        other: number
      }
    | undefined
  countryData:
    | {
        value: number
        label: string
        region: string
        ids: number[]
      }[]
    | undefined
  countryLoading: boolean
  oblastData: any
  oblastLoading: boolean
  searchData: any
  searchLoading: boolean
}

const initialState: IinitialState = {
  loadingMap: false,
  mapData: undefined,
  loadingByStateShares: false,
  dataByStateShares: undefined,
  loadingByPeriod: false,
  dataByPeriod: undefined,
  loadingByMethods: false,
  dataByMethods: undefined,
  countryData: undefined,
  countryLoading: false,
  oblastData: undefined,
  oblastLoading: false,
  searchData: undefined,
  searchLoading: false,
}

export const StatisticSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getSearchData.pending, (state) => {
      state.searchLoading = true
    })
    builder.addCase(getSearchData.fulfilled, (state, { payload }) => {
      state.searchLoading = false
      state.searchData = payload
    })
    builder.addCase(getSearchData.rejected, (state) => {
      state.searchLoading = false
    })
    //--------------------
    builder.addCase(getOblast.pending, (state) => {
      state.oblastLoading = true
    })
    builder.addCase(getOblast.fulfilled, (state, { payload }) => {
      state.oblastLoading = false
      state.oblastData = payload
    })
    builder.addCase(getOblast.rejected, (state) => {
      state.oblastLoading = false
    })
    //--------------------
    builder.addCase(getCountry.pending, (state) => {
      state.countryLoading = true
    })
    builder.addCase(getCountry.fulfilled, (state, { payload }) => {
      state.countryLoading = false
      state.countryData = payload
    })
    builder.addCase(getCountry.rejected, (state) => {
      state.countryLoading = false
    })
    //--------------------
    builder.addCase(getByPeriod.pending, (state) => {
      state.loadingByPeriod = true
    })
    builder.addCase(getByPeriod.fulfilled, (state, { payload }) => {
      state.loadingByPeriod = false
      state.dataByPeriod = payload
    })
    builder.addCase(getByPeriod.rejected, (state) => {
      state.loadingByPeriod = false
    })
    //--------------------
    builder.addCase(getByStateShares.pending, (state) => {
      state.loadingByStateShares = true
    })
    builder.addCase(getByStateShares.fulfilled, (state, { payload }) => {
      state.loadingByStateShares = false
      state.dataByStateShares = payload
    })
    builder.addCase(getByStateShares.rejected, (state) => {
      state.loadingByStateShares = false
    })
    //--------------------
    builder.addCase(getByMethods.pending, (state) => {
      state.loadingByMethods = true
    })
    builder.addCase(getByMethods.fulfilled, (state, { payload }) => {
      state.loadingByMethods = false
      state.dataByMethods = payload
    })
    builder.addCase(getByMethods.rejected, (state) => {
      state.loadingByMethods = false
    })
    //--------------------
  },
})
