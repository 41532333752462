import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from '../../api/axios-config'
import toast from 'react-hot-toast'

export const getCourtCase = createAsyncThunk(
  'get/CourtCase',
  async (
    { page, pageSize }: { page: number; pageSize: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.get(`court-case/${page}/${pageSize}`)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const getCourtCasePublic = createAsyncThunk(
  'get/CourtCase/public',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`court-case/public`)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const postCourtCase = createAsyncThunk(
  'post/CourtCase',
  async (body: postData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`court-case`, body)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

export const putCourtCase = createAsyncThunk(
  'put/CourtCase',
  async ({ id, body }: { id: number; body: postData }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`court-case/${id}`, body)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error((error as Error).message)
      rejectWithValue((error as Error).message)
    }
  }
)

export const removeCourtCase = createAsyncThunk(
  'remove/CourtCase',
  async ({ id }: { id: any }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`court-case/${id}`)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      toast.error('Возможно этот элемент используется')
      rejectWithValue((error as Error).message)
    }
  }
)

interface INumbers {
  count: number | null
  processCount: number | null
}

interface postData {
  periodStart: string
  periodEnd: string
  active: boolean
  plaintiff: INumbers | null
  defendant: INumbers | null
  interested: INumbers | null
}
interface data {
  id: number
  periodStart: string
  periodEnd: string
  active: boolean
  plaintiff: INumbers
  defendant: INumbers
  interested: INumbers
  total: INumbers
}

interface IInitialState {
  loadingCourtCase: boolean
  CourtCaseData: {
    page: data[]
    totalCount: number
  } | null
  fetchCheck: any
  publicData: any
}

const initialState: IInitialState = {
  loadingCourtCase: false,
  CourtCaseData: null,
  fetchCheck: null,
  publicData: undefined,
}

export const CourtCaseSlice = createSlice({
  name: 'CourtCase',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getCourtCasePublic.pending, (state) => {
      state.loadingCourtCase = true
    })
    builder.addCase(getCourtCasePublic.fulfilled, (state, { payload }) => {
      state.loadingCourtCase = false
      state.publicData = payload
    })
    builder.addCase(getCourtCasePublic.rejected, (state) => {
      state.loadingCourtCase = false
    })
    //--------------------
    builder.addCase(getCourtCase.pending, (state) => {
      state.loadingCourtCase = true
    })
    builder.addCase(getCourtCase.fulfilled, (state, { payload }) => {
      state.loadingCourtCase = false
      state.CourtCaseData = payload
    })
    builder.addCase(getCourtCase.rejected, (state) => {
      state.loadingCourtCase = false
    })
    //--------------------
    builder.addCase(postCourtCase.pending, (state) => {
      state.loadingCourtCase = true
    })
    builder.addCase(postCourtCase.fulfilled, (state, { payload }) => {
      state.loadingCourtCase = false
      state.fetchCheck = payload
    })
    builder.addCase(postCourtCase.rejected, (state) => {
      state.loadingCourtCase = false
    })
    //--------------------
    builder.addCase(putCourtCase.pending, (state) => {
      state.loadingCourtCase = true
    })
    builder.addCase(putCourtCase.fulfilled, (state, { payload }) => {
      state.loadingCourtCase = false
      state.fetchCheck = payload
    })
    builder.addCase(putCourtCase.rejected, (state) => {
      state.loadingCourtCase = false
    })
    //--------------------
    builder.addCase(removeCourtCase.pending, (state) => {
      state.loadingCourtCase = true
    })
    builder.addCase(removeCourtCase.fulfilled, (state, { payload }) => {
      state.loadingCourtCase = false
      state.fetchCheck = payload
    })
    builder.addCase(removeCourtCase.rejected, (state) => {
      state.loadingCourtCase = false
    })
  },
})
