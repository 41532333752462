export const saveTolocalStorage = (key: any, data: any) => {
  try {
    localStorage.setItem(key, JSON.stringify(data))
  } catch (error) {
    window.alert((error as Error).message)
  }
}

// export function setCookie(name: any, value: any, days: any, domain: any) {
//   var expires = "";
//   if (days) {
//     var date = new Date();
//     date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
//     expires = "; expires=" + date.toUTCString();
//   }
//   document.cookie =
//     name + "=" + (value || "") + expires + "; path=/; domain=" + domain;
// }

export function setCookie(name: any, value: any, days: any) {
  var expires = ''
  if (days) {
    var date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = '; expires=' + date.toUTCString()
  }
  document.cookie = name + '=' + (value || '') + expires
}

export function deleteCookie(name: any) {
  return new Promise((resolve, reject) => {
    try {
      document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;'
      resolve(`Cookie ${name} удален`)
    } catch (error) {
      reject(error)
    }
  })
}

export function getCookie(name: any) {
  var value = '; ' + document.cookie
  var parts = value.split('; ' + name + '=')
  if (parts.length === 2) return parts.pop()?.split(';').shift()
}
