import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from '../../api/axios-config'
import toast from 'react-hot-toast'
import axios from 'axios'

export const getLocalityTypes = createAsyncThunk(
  'get/locality/types',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`locality/types`)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const getLocality = createAsyncThunk(
  'get/locality',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`locality/tree`)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const getLocalitySimpleField = createAsyncThunk(
  'get/localitySimple',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`locality/simplefield`)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const postLocality = createAsyncThunk(
  'post/locality',
  async (body: IPostLocality, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`locality`, body)
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toast.error(error.response?.data.message)
        return rejectWithValue((error as Error).message)
      }
    }
  }
)

export const putLocality = createAsyncThunk(
  'put/locality',
  async ({ id, body }: { id: number; body: postData }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`locality/${id}`, body)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const removeLocality = createAsyncThunk(
  'remove/locality',
  async ({ id, type }: any, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`locality/${type}/${id}`)
      return response.data
    } catch (error) {
      rejectWithValue((error as Error).message)
    }
  }
)

export const banneLocality = createAsyncThunk(
  'banned/locality',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`locality/banned/${id}`)
      toast.success(response.data.message)
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toast.error(error.response?.data.message)
        return rejectWithValue((error as Error).message)
      }
    }
  }
)
interface IPostLocality {
  code: string
  name: string
  parentId: number | null
  typeId: number
}

interface postData {
  name: string
  code: string
  leaf: string
  active: boolean
  type: string
}

interface ITypes {
  id: number
  text: string
}

interface IInitialState {
  loadingLocality: boolean
  userLocalData: any
  fullData: any
  typesData: ITypes[] | null | undefined
  fetchCheck: boolean
}

const initialState: IInitialState = {
  loadingLocality: false,
  userLocalData: null,
  fullData: null,
  typesData: null,
  fetchCheck: false,
}

export const LocalitySlice = createSlice({
  name: 'locality',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getLocalityTypes.pending, (state) => {
      state.loadingLocality = true
    })
    builder.addCase(getLocalityTypes.fulfilled, (state, { payload }) => {
      state.loadingLocality = false
      state.typesData = payload
    })
    builder.addCase(getLocalityTypes.rejected, (state) => {
      state.loadingLocality = false
    })
    //--------------------
    builder.addCase(getLocality.pending, (state) => {
      state.loadingLocality = true
    })
    builder.addCase(getLocality.fulfilled, (state, { payload }) => {
      state.loadingLocality = false
      state.fullData = payload
    })
    builder.addCase(getLocality.rejected, (state) => {
      state.loadingLocality = false
    })
    //--------------------
    builder.addCase(getLocalitySimpleField.pending, (state) => {
      state.loadingLocality = true
    })
    builder.addCase(getLocalitySimpleField.fulfilled, (state, { payload }) => {
      state.loadingLocality = false
      state.userLocalData = payload
    })
    builder.addCase(getLocalitySimpleField.rejected, (state) => {
      state.loadingLocality = false
    })
    //--------------------
    builder.addCase(postLocality.pending, (state) => {
      state.loadingLocality = true
    })
    builder.addCase(postLocality.fulfilled, (state) => {
      state.loadingLocality = false
      state.fetchCheck = !state.fetchCheck
    })
    builder.addCase(postLocality.rejected, (state) => {
      state.loadingLocality = false
    })
    //--------------------
    builder.addCase(putLocality.pending, (state) => {
      state.loadingLocality = true
    })
    builder.addCase(putLocality.fulfilled, (state) => {
      state.loadingLocality = false
      state.fetchCheck = !state.fetchCheck
    })
    builder.addCase(putLocality.rejected, (state) => {
      state.loadingLocality = false
    })
    //--------------------
    builder.addCase(removeLocality.pending, (state) => {
      state.loadingLocality = true
    })
    builder.addCase(removeLocality.fulfilled, (state) => {
      state.loadingLocality = false
      state.fetchCheck = !state.fetchCheck
    })
    builder.addCase(removeLocality.rejected, (state) => {
      state.loadingLocality = false
    })
    //--------------------
    builder.addCase(banneLocality.pending, (state) => {
      state.loadingLocality = true
    })
    builder.addCase(banneLocality.fulfilled, (state) => {
      state.loadingLocality = false
      state.fetchCheck = !state.fetchCheck
    })
    builder.addCase(banneLocality.rejected, (state) => {
      state.loadingLocality = false
    })
  },
})
